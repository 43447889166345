(function () {
  'use strict';
  const angular = window.angular;

  Service.$inject = ['$translate', '$rootScope', '$timeout', 'AppUtils'];

  angular.module('commonServices').service('AgGridService', Service);

  function Service($translate, $root, $timeout, utils) {
    return {
      newInstance,
      fitColumns,
    };

    function newInstance(target, options) {
      const defaults = {
        getLocaleText: localeTextFunc,
      };

      return new agGrid.Grid(target, Object.assign(defaults, options));
    }

    function localeTextFunc(params) {
      const key = params.key;
      const defaultValue = params.default;
      const gridKey = 'ag-grid.' + key;
      const keys = $translate.getTranslationTable($root.currentLang) || {};
      return keys[gridKey] ? $translate.instant(gridKey) : defaultValue;
    }

    function fitColumns(viewModel) {
      return function () {
        return utils.Promise((resolve) => {
          if (viewModel.resizing) {
            return resolve();
          }

          viewModel.resizing = true;
          $timeout(() => {
            if (!viewModel.dtInstance) {
              return;
            }
            viewModel.dtInstance.api.sizeColumnsToFit();
            viewModel.resizing = false;
            return resolve();
          }, 100);
        });
      };
    }
  }
})();
