;(function () {
  const $ = window.$;

  gisService.$inject = ['Customer', 'AppUtils', 'GisUtils', 'AssetService', 'SensorService', '$q', '$timeout'];

  angular
    .module('commonServices')
    .service('GisService', gisService);

  function gisService (Customer, utils, gisUtils, assetService, sensorService, $q, $timeout) {
    this.saveGIS = saveGIS;
    this.loadAssetFigure = loadAssetFigure;
    this.refreshFigureState = refreshFigureState;
    this.refreshVariableState = refreshVariableState;

    function saveGIS (gisId, customerId, projectId, gis) {
      let promise;
      if (gisId) {
        promise = Customer
          .prototype$__updateById__projects__gis({
            id: customerId,
            nk: projectId,
            fk: gisId
          }, gis)
          .$promise;

      } else {
        promise = Customer
          .prototype$__create__projects__gis({
            id: customerId,
            nk: projectId
          }, gis)
          .$promise;
      }

      return promise
        .catch((response) => {
          throw utils.getHTTPError(response);
        });
    }

    function loadAssetFigure (figure, vm, notSelect) {
      let defer = $q.defer();
      let assetId = figure.properties.asset;
      if (!assetId || !vm.assets[assetId]) {
        defer.resolve();
        return defer.promise;
      }

      const asset = vm.assets[assetId];
      const figures = vm.assetFigures;

      if (!asset) {
        figure.sensor = null;
        figure.tempSensor = null;
        figure.sensors = {};
        figure.useForAlerts = false;
        figure.assetVisible = false;
        if (figure.geoType === 'point') {
          figure.set('labelContent', '');
          figure.set('labelVisible', false);
        } else if (figure.label && typeof figure.label.setMap === 'function') {
          figure.label.setMap(null);
        }
        defer.resolve();
        return defer.promise;
      }

      if (figures[asset.id] && figures[asset.id] !== figure) {
        figures[asset.id].asset = null;
      }

      if (figure.geoType === 'point') {
        figure.setOptions({ title: asset.name });
      }

      figures[asset.id] = figure;

      figure.asset = figure.newAsset = asset;
      return assetService
        .findById(asset.id, { include: ['sensors', 'assetStates'] })
        .then(function (asset) {
          figure.asset = asset;

          if (figure.geoType === 'polygon' && figure.label) {
            let text = asset.name;

            if (text.length > 20) {
              text = text.substring(0, 19) + '…';
            }

            figure.label.set('label', {
              text: text,
              fontSize: '13px',
              fontWeight: 'bold',
              color: '#ffffff'
            });

            figure.label.set('title', asset.name);
          }

          vm.toggleInfo(figure);
          asset.sensors = asset.sensors || [];

          if (Array.isArray(figure.properties.sensors)) {
            figure.properties.sensors.forEach(function (current) {
              let sensor = asset.sensors.find(sensor => sensor.id === current);

              figure.sensors[current] = { sensor: sensor };
            });
          }

          asset.sensors.forEach(function (sensor) {
            sensor.figure = figure;
            vm.sensors[sensor.id] = sensor;
            sensor.map = {};
            sensor.map.checked = figure.sensor === sensor.id || (figure.sensors && figure.sensors[sensor.id] !== undefined);
            if (sensor._lastData) {
              const last = {
                timestamp: sensor._lastData.from,
                value: sensorService.getNumberValue(sensor._lastData, sensor.type)
              };

              sensor._lastData = last;
              if (figure.sensor === sensor.id) {
                refreshFigureState(figure, sensor, last);
              } else {
                refreshVariableState(figure, sensor, last);
              }
            } else {
              if (figure.sensor === sensor.id) {
                refreshFigureState(figure, sensor, null);
              } else {
                refreshVariableState(figure, sensor, null);
              }
            }
          });
          return asset;
        });
    }

    function refreshFigureState (figure, sensor, data, cb) {
      if (!figure) {
        return;
      }

      cb = typeof cb === 'function' ? cb : function () {};
      if (!sensor || !data) {
        gisUtils.setFigureState(figure, '#ffffff');
        return cb();
      }

      sensor.figure = figure;
      refreshVariableState(figure, sensor, data, (obj) => {
        gisUtils.setFigureState(figure, obj ? obj.color : '#ffffff');
        cb();
      });
    }

    function refreshVariableState (figure, sensor, data, options, cb) {
      if (!options || typeof options === 'function') {
        cb = options;
        options = { updateInfoWindow: true };
      }
      cb = typeof cb === 'function' ? cb : function () {};

      if (!figure || !sensor) {
        return cb();
      }

      let obj = sensor.id === figure.sensor ? figure : figure.sensors[sensor.id];

      if (!obj) {
        return cb();
      }

      obj.value = data ? data.value : null;
      obj.timestamp = data ? data.timestamp : null;
      obj.sensor = sensor.id;

      const accuracy = sensorService.getSensorAccuracy(sensor);
      sensorService
        .getThreshold(sensor, figure.asset.currentStateId, obj.value)
        .then(threshold => {
          obj.color = threshold ? threshold.color : null;
          obj.threshold = threshold;

          if (options.updateInfoWindow) {
            let info = $('.gmaps-info .' + sensor.id);
            let val = info.find('.sensor-value');

            val.each(function () {
              let valueText = isNaN(parseFloat(obj.value)) ? '-' : $filter('number')(obj.value, accuracy) + (sensor.unit ? ' [' + sensor.unit + ']' : '');
              let dateText = $filter('date')(obj.timestamp);

              let content = `<span class="label" style="background: ${obj.color}; color: ${utils.getTextColor(obj.color)}" title="${dateText}">${valueText}</span>`;
              $(this)
                .empty()
                .append(content);
            });
          }
          return cb(obj);
        })
        .catch(err => {
          console.log(err);
          cb();
        });
    }
  }
})();
