(function () {
  gisDistanceService.$inject = [];

  angular.module('commonServices').service('GisDistanceService', gisDistanceService);

  function gisDistanceService() {
    this.getTotalDistance = getTotalDistance;
    this.getPointAtDistance = getPointAtDistance;
    this.kmsBetween = distance;
    this.closestMarkers = closestMarkers;

    function getTotalDistance(figure) {
      if (figure.geoType !== 'lineString' || figure.getPath().getLength() < 2) {
        return null;
      }
      let dist = 0;

      for (let i = 1; i < figure.getPath().getLength(); i++) {
        dist += distance(
          figure.getPath().getAt(i).toJSON(),
          figure
            .getPath()
            .getAt(i - 1)
            .toJSON()
        );
      }

      return dist;
    }

    function getPointAtDistance(figure, meters) {
      if (figure.geoType !== 'lineString') {
        return null;
      }
      // some awkward special cases
      if (meters === 0) {
        return figure.getPath().getAt(0).toJSON();
      }

      if (meters < 0 || figure.getPath().getLength() < 2) {
        return;
      }

      let i;
      let dist = 0;
      let olddist = 0;
      for (i = 1; i < figure.getPath().getLength() && dist < meters; i++) {
        olddist = dist;
        dist += distance(
          figure.getPath().getAt(i).toJSON(),
          figure
            .getPath()
            .getAt(i - 1)
            .toJSON()
        );
      }

      if (dist < meters) {
        return null;
      }
      const p1 = figure.getPath().getAt(i - 2);
      const p2 = figure.getPath().getAt(i - 1);
      const m = (meters - olddist) / (dist - olddist);
      return {
        lat: p1.lat() + (p2.lat() - p1.lat()) * m,
        lng: p1.lng() + (p2.lng() - p1.lng()) * m,
      };
    }

    function distance(c1, c2) {
      const p = 0.017453292519943295; // Math.PI / 180
      const cos = Math.cos;
      const a =
        0.5 -
        cos((c2.lat - c1.lat) * p) / 2 +
        (cos(c1.lat * p) * cos(c2.lat * p) * (1 - cos((c2.lng - c1.lng) * p))) / 2;

      return 12742 * Math.asin(Math.sqrt(a));
    }

    function closestMarkers(position, markers, n) {
      n = n || 1;

      const distances = [];
      markers.forEach((current) => {
        distances.push({
          distance: distance(position, current.getPosition().toJSON()),
          marker: current,
        });
      });

      distances.sort((a, b) => a.distance - b.distance);
      return n === 1 ? distances[0] : distances.slice(0, n);
    }
  }
})();
