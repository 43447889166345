;(function () {
  'use strict';
  const angular = window.angular;

  IconService.$inject = ['$http', '$q'];

  angular
    .module('commonServices')
    .service('iconService', IconService);

  function IconService ($http, $q) {
    const icons = {
      assets: {
        base: window.assetsPath + '/images/config/assets',
        icons: {
          battery: {
            folder: 'batteries',
            icons: {
              battery: '01.png',
              batteries: '02.png',
              stack: '03.png',
              ups: 'ups.png'
            }
          },
          belt: {
            folder: 'belts',
            icons: {
              '01': '01.png',
              '02': '02.png',
              '03': '03.png',
              '04': '04.png',
              '05': '05.png'
            }
          },
          building: {
            folder: 'buildings',
            icons: {
              '01': '01.png',
              '02': '02.png',
              '03': '03.png',
              city: 'city.png',
              clinic: 'clinic.png',
              enterprise: 'enterprise.png',
              factory: 'factory.png',
              farm: 'farm.png',
              hospital: 'hospital.png',
              house: 'house.png',
              industry: 'industry.png',
              mall: 'mall.png',
              public: 'public.png',
              residential: 'residential.png',
              store: 'store.png'
            }
          },
          camera: {
            folder: 'cameras',
            icons: {
              '01': '01.png',
              '02': '02.png',
              '03': '03.png',
              '04': '04.png',
              '05': '05.png',
              '06': '06.png',
              '07': '07.png',
              '08': '08.png',
              '09': '09.png',
              '10': '10.png',
              '11': '11.png',
              '12': '12.png'
            }
          },
          crusher: {
            folder: 'crushers',
            icons: {
              '01': '01.png',
              '02': '02.png',
              '03': '03.png',
              '04': '04.png'
            }
          },
          drum: {
            folder: 'drums',
            icons: {
              '01': '01.png',
              '02': '02.png'
            }
          },
          gateway: {
            folder: 'gateways',
            icons: {
              '01': '01.png',
              '02': '02.png',
              '03': '03.png',
              '04': '04.png',
              '05': '05.png',
              '06': '06.png'
            }
          },
          hopper: {
            folder: 'hoppers',
            icons: {
              '01': '01.png',
              '02': '02.png'
            }
          },
          other: {
            folder: 'others',
            icons: {
              '01': '01.png',
              chemical: 'chemical.png',
              datacenter: 'datacenter.png',
              electrical: 'electrical.png',
              greenhouse: 'greenhouse.png',
              mine: 'mine.png',
              oil: 'oil.png',
              telecommunication: 'telecommunication.png'
            }
          },
          outdoor: {
            folder: 'outdoor',
            icons: {
              beach: 'beach.png',
              field: 'field.png',
              mountain: 'mountain.png'
            }
          },
          pump: {
            folder: 'pumps',
            icons: {
              '01': '01.png',
              '02': '02.png',
              '03': '03.png',
              '04': '04.png',
              '05': '05.png',
              '06': '06.png',
              '07': '07.png',
              '08': '08.png',
              '09': '09.png',
              '10': '10.png',
              '11': '11.png',
              '12': '12.png',
              '13': '13.png'
            }
          },
          tripper: {
            folder: 'trippers',
            icons: {
              '01': '01.png',
              '02': '02.png'
            }
          },
          sieve_screen: {
            folder: 'sieve_screens',
            icons: {
              '01': '01.png',
              '02': '02.png'
            }
          },
          valve: {
            folder: 'valves',
            icons: {
              '01': '01.png',
              '02': '02.png',
              '03': '03.png',
              '04': '04.png',
              '05': '05.png'
            }
          },
          vessel: {
            folder: 'vessels',
            icons: {
              '01': '01.png',
              '02': '02.png',
              '03': '03.png',
              '04': '04.png',
              '05': '05.png',
              '06': '06.png',
              '07': '07.png',
              '08': '08.png',
              '09': '09.png',
              '10': '10.png',
              '11': '11.png',
              '12': '12.png',
              '13': '13.png',
              '14': '14.png',
              '15': '15.png'
            }
          }
        }
      }
    };

    function getAssetIcons () {
      return $q.resolve(icons.assets);
    }

    function getGatewayIcons () {
      return $q.resolve(icons.gateways);
    }

    function getAssetIcon (asset, color) {
      if (!asset) { return; }
      color = color || 'black';

      let path = 'generic.png';
      if (asset.icon) {
        let parts = asset.icon.split(/\.|\//);
        let type = parts[0];
        let icon = parts[1];

        const category = icons.assets.icons[type];
        if (category && category.icons[icon]) {
          path = category.folder + '/' + category.icons[icon];
        }
      }

      return icons.assets.base + '/' + color + '/' + path;
    }

    return {
      getAssetIcons: getAssetIcons,
      getGatewayIcons: getGatewayIcons,
      getAssetIcon: getAssetIcon
    };
  }
})();
