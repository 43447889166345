(function () {
  'use strict';
  const angular = window.angular;

  EdgeAgentService.$inject = ['$interpolate', 'EdgeAgentTemplate', '$rootScope', 'AppUtils'];

  angular.module('commonServices').service('EdgeAgentService', EdgeAgentService);

  function EdgeAgentService($interpolate, EdgeAgentTemplate, $rootScope, utils) {
    const bannedKeys = [
      'assets',
      'name',
      'enabled',
      'image',
      'sharedPath',
      'created',
      'modified',
      'deleted',
      'id',
      'customerId',
      'deviceId',
      'device',
      'templateTagId',
      'templateId',
      'edgeAgentAssets',
    ];
    const enabledKeys = ['broker', 'brokers', 'modules', 'routes', 'moduleParameters', 'systems'];

    return {
      getAgentBody,

      getEdgeAgentSchema: () => {
        return {
          $id: '#edgeAgent',
          type: 'object',
          definitions: {
            module: {
              $id: '#/properties/module',
              type: 'object',
              minProperties: 1,
              required: ['image'],
              properties: {
                image: {
                  $id: '#/properties/module/properties/image',
                  type: 'string',
                },
                debug: {
                  $id: '#/properties/module/properties/debug',
                  type: 'boolean',
                },
                canSpawn: {
                  $id: '#/properties/module/properties/canSpawn',
                  type: 'boolean',
                  default: false,
                },
              },
            },
            moduleParameter: {
              $id: '#/properties/moduleParameter',
              type: ['object', 'null'],
              patternProperties: {
                '.{1,}': {},
              },
            },
            routeItem: {
              $id: '#/properties/routeItem',
              type: 'object',
              patternProperties: {
                '.{1,}': {
                  type: 'array',
                  minItems: 1,
                  uniqueItems: true,
                  items: {
                    type: 'string',
                  },
                },
              },
            },
            route: {
              $id: '#/properties/route',
              type: ['object', 'null'],
              patternProperties: {
                '.{1,}': { $ref: '#/definitions/routeItem' },
              },
            },
          },
          dependencies: {
            broker: ['modules'],
            modules: ['broker'],
          },
          properties: {
            broker: {
              $id: '#/properties/broker',
              type: 'object',
              additionalProperties: false,
              minProperties: 1,
              required: ['host'],
              properties: {
                namespace: {
                  $id: '#/properties/broker/properties/namespace',
                  type: 'string',
                  default: '',
                  pattern: '^(.{3,})$',
                },
                host: {
                  $id: '#/properties/broker/properties/hostname',
                  type: 'string',
                  default: '',
                  pattern: '^(.*)$',
                },
                port: {
                  $id: '#/properties/broker/properties/port',
                  type: 'integer',
                  default: 0,
                },
                qos: {
                  $id: '#/properties/broker/properties/qos',
                  type: 'string',
                  default: 'exactly-once',
                  enum: ['at-most-once', 'at-least-once', 'exactly-once'],
                },
              },
            },
            modules: {
              $id: '#/properties/modules',
              type: 'object',
              patternProperties: {
                '.{1,}': { $ref: '#/definitions/module' },
              },
            },
            routes: {
              $id: '#/properties/routes',
              type: 'object',
              patternProperties: {
                '.{1,}': { $ref: '#/definitions/route' },
              },
            },
            moduleParameters: {
              $id: '#/properties/moduleParameters',
              type: 'object',
              patternProperties: {
                '.{1,}': { $ref: '#/definitions/moduleParameter' },
              },
            },
            systems: {
              $id: '#/properties/system',
              type: 'object',
            },
          },
        };
      },

      interpolateTemplate,
      interpolateTemplateProcess,

      recreateAgentFromTemplate,
    };

    function getAgentBody(agent) {
      agent = angular.copy(agent);
      const temp = {};

      enabledKeys.forEach((key) => {
        temp[key] = agent[key];
      });
      return temp;
    }

    function bind(template) {
      if (typeof template == 'object') {
        template = JSON.stringify(template);
      }
      return tryToParseJSON(
        template.replace(/"bind::(.*?)"/g, (match, content) => (content ? content : 'null'))
      );
    }

    function applyInterpolation(template, data) {
      let tmpTemplate = template;
      if (['object', 'string'].indexOf(typeof template) === -1) {
        return template;
      }
      if (typeof template == 'object') {
        tmpTemplate = JSON.stringify(template);
      }

      tmpTemplate = tmpTemplate.replace(/\$\$index/g, '&&index');
      let templateStr = tmpTemplate.replace(/{{(.*?)}}/g, (match) => match.replace(/\$/g, '.'));

      templateStr = templateStr.replace(/&&index/g, '$$$$index');
      templateStr = templateStr.replace(/\.config/g, '._config');
      const exp = $interpolate(templateStr);
      templateStr = exp(data);
      if (templateStr.startsWith('bind::')) {
        templateStr = templateStr.replace('bind::', '');
      }
      return typeof template == 'object' ? JSON.parse(templateStr) : tryToParseJSON(templateStr);
    }

    function forResolver(iterator, variable, template, data) {
      let resolved = {};
      if (typeof template === 'object') {
        if (template instanceof Array) resolved = [];
      } else return applyInterpolation(template, data);
      let subIterators = iterator.split('$');
      let items = data;
      for (let si of subIterators) {
        if (items[si]) {
          items = items[si];
        }
      }
      if (items) {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          let scope = {};
          for (let key in data) scope[key] = data[key];
          scope[variable] = item;
          scope.$$index = i;

          if (template instanceof Array) {
            for (let st in template) {
              let subTemplate = template[st];
              let result = applyInterpolation(
                interpolateTemplateProcess(subTemplate, scope),
                scope
              );
              if (resolved instanceof Array) resolved.push(result);
              else {
                for (let key in result) resolved[key] = result[key];
              }
            }
          } else {
            let result = applyInterpolation(interpolateTemplateProcess(template, scope), scope);
            if (resolved instanceof Array) resolved.push(result);
            else {
              for (let key in result) resolved[key] = result[key];
            }
          }
        }
      }
      return resolved;
    }

    function interpolateTemplateProcess(template, data) {
      if (template && typeof template === 'object') {
        let response = template instanceof Array ? [] : {};
        for (let key in template) {
          let value = template[key];
          // Recognize for
          let match = (key.match(/{{#for,(.*?)}}/g) || [])[0];
          if (match) {
            let [iterator, variable] = match.replace('{{#for,', '').replace('}}', '').split(',');
            let resolved = forResolver(iterator, variable, value, data);
            if (value instanceof Array) {
              response = [];
              for (let i in resolved) response.push(resolved[i]);
            } else {
              for (let i in resolved) response[i] = resolved[i];
            }
          } else {
            const interpolatedKey = applyInterpolation(key, data);
            response[interpolatedKey] = interpolateTemplateProcess(value, data);
          }
        }
        return response;
      } else {
        return applyInterpolation(template, data);
      }
    }

    function interpolateTemplate(template, data) {
      template = JSON.parse(JSON.stringify(template));
      return bind(interpolateTemplateProcess(template, data));
    }

    function tryToParseJSON(str) {
      try {
        return JSON.parse(str);
      } catch (e) {
        return str;
      }
    }

    function recreateAgentFromTemplate(templateId, templateTagId, agent, device, assets) {
      return EdgeAgentTemplate.v2Generate(
        {
          id: templateId,
          fk: templateTagId,
        },
        {
          customerId: $rootScope.customerId,
          edgeAgent: agent,
          deviceId: device?.id,
          assetIds: assets?.map((asset) => asset.id),
        }
      ).$promise.catch((err) => {
        throw utils.getHTTPError(err);
      });

      template = getAgentBody(template);
      try {
        const firstAsset = assets.length ? assets[0] : null;
        const parent = firstAsset ? firstAsset.asset : null;
        let interpolated = interpolateTemplate(template, {
          name: agent.name,
          image:
            typeof agent.image === 'object'
              ? agent.image.name + ':' + agent.image.version
              : agent.image,
          sharedPath: agent.sharedPath,
          asset: firstAsset,
          assets: assets,
          parent: parent,
          device: device,
        });

        for (let key in interpolated) {
          agent[key] = interpolated[key];
        }
      } catch (err) {
        console.log(err);
        throw new Error('entities.edgeagenttemplate.messages.cantInterpolate');
      }
    }
  }
})();
