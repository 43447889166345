(function () {
  let angular = window.angular;

  KPIEngineService.$inject = [
    'Customer',
    'Sensor',
    'SensorService',
    'VirtualExpressionService',
    'AppUtils',
    'DateUtils',
  ];

  angular.module('commonServices').service('KPIEngineService', KPIEngineService);

  function KPIEngineService(
    Customer,
    Sensor,
    SensorService,
    VirtualExpressionService,
    utils,
    DateUtils
  ) {
    return {
      solveVirtualExpressions: solveVirtualExpressions,
      evaluateVirtualExpressions: evaluateVirtualExpressions,
    };

    function evaluateVirtualExpressions(virtualExpressionIds, options) {
      const promises = [];

      for (let i = 0; i < virtualExpressionIds.length; i++) {
        const virtualExpressionId = virtualExpressionIds[i];
        const currentOptions = options[i];

        let from = currentOptions.from;
        if (typeof currentOptions.from === 'number') {
          from = new Date(currentOptions.from);
        }

        promises.push({
          function: Customer.prototype$__evaluate__projects__virtualExpressions,
          args: [
            {
              id: currentOptions.customerId,
              nk: currentOptions.projectId,
              fk: virtualExpressionId,
              from: from,
              to: currentOptions.to,
              group_intervals: currentOptions.groupIntervals,
              group_mode: currentOptions.groupMode,
              group_utc: -from.getTimezoneOffset() / 60,
            },
          ],
        });
      }

      return utils.parallelPromises(promises).then((result) => {
        const errors = result.errors.filter((current) => !!current);
        if (errors.length) {
          console.log(errors);
        }

        result = result.success;
        return result.reduce((map, current) => {
          map[current.virtualExpressionId] = current.data;
          return map;
        }, {});
      });
    }

    /**
     *
     * @param {string[]} virtualExpressionIds
     * @param {Object} options
     * @param {string} options.customerId
     * @param {string} options.projectId
     * @param {Date} [options.from]
     * @param {Date} [options.to]
     * @returns {Promise<>}
     */
    function solveVirtualExpressions(virtualExpressionIds, options) {
      options = angular.copy(options || {});

      virtualExpressionIds = Array.from(new Set(virtualExpressionIds));

      if (virtualExpressionIds.length === 0) {
        return Promise.resolve({});
      }

      let sensorIds = {};
      const virtualVariables = {};
      const expressionHash = {};
      const expressionVariables = {};
      const summaries = {};
      let virtualExpressions;

      return VirtualExpressionService.find(options.customerId, options.projectId, {
        where: { id: { inq: virtualExpressionIds } },
        include: ['virtualVariables'],
      })
        .then((result) => {
          virtualExpressions = result;

          if (!virtualExpressions.length) {
            return Promise.resolve({});
          }

          virtualExpressions.forEach((current) => {
            expressionHash[current.id] = current;
            current.variables = VirtualExpressionService.getVariables(current.expression);
            Object.assign(expressionVariables, current.variables);

            current.virtualVariables.forEach((virtualVariable) => {
              virtualVariables[virtualVariable.id] = virtualVariable;
              if (virtualVariable.sensorId) {
                sensorIds[virtualVariable.sensorId] = virtualVariable.sensorId;
              }
            });
          });

          virtualExpressions.forEach((current) => {
            for (let expression in expressionVariables) {
              if (current.variables[expression]) {
                current.variables[expression] = expressionVariables[expression];
              }
            }
          });

          const promises = [];
          for (let sensorId in sensorIds) {
            promises.push({
              function: getSensorSummariesByHour,
              args: [sensorId, summaries, options],
            });
          }

          return utils.parallelPromises(promises);
        })
        .then((result) => {
          result.errors = (result.errors || []).filter((error) => !!error);
          if (result.errors.length) {
            console.log(result.errors);
          }
          for (const variableId in expressionVariables) {
            const expressionVariable = expressionVariables[variableId];
            expressionVariable.data = expressionVariable.data || {
              hour: {},
              day: {},
              week: {},
              month: {},
              year: {},
            };

            const virtualVariable = virtualVariables[expressionVariable.variable];

            if (!virtualVariable || !virtualVariable.sensorId) {
              continue;
            }

            groupVariableDataByTime(expressionVariable, virtualVariables, summaries);
          }

          virtualExpressions.forEach((current) => {
            getVirtualExpressionsSolved(current, options);
          });

          result = utils.arrayToObject(virtualExpressions, 'id');

          // Prevent errors if a virtual variables does not exist
          virtualExpressionIds.forEach((virtualExpressionId) => {
            if (!result[virtualExpressionId]) {
              result[virtualExpressionId] = {
                data: { hour: {}, day: {}, week: {}, month: {}, year: {} },
              };
            }
          });

          return result;
        })
        .catch((err) => {
          err = utils.getHTTPError(err);
          console.error(err);
          throw err;
        });
    }

    /**
     *
     * @param {string} sensorId
     * @param {Object} summaries
     * @param {Object} options
     * @param {int} options.from
     * @param {int} options.to
     * @param {Object} options.filter
     * @returns {Promise<any>}
     */
    function getSensorSummariesByHour(sensorId, summaries, options) {
      return SensorService.getSummaries(sensorId, options.from, options.to)
        .then((result) => {
          summaries[sensorId] = summaries[sensorId] || {};
          result.forEach((summary) => {
            summaries[sensorId][summary.from.getTime()] = summary;
          });
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    }

    /**
     *
     * @param {Object} expressionVariable variable parsed by MathJS
     * @param {Object} virtualVariables hash of virtualVariables
     * @param {Object} summaries hash of summaries by sensor
     */
    function groupVariableDataByTime(expressionVariable, virtualVariables, summaries) {
      const virtualVariable = virtualVariables[expressionVariable.variable];
      if (!virtualVariable) {
        return;
      }

      const sensorId = virtualVariable.sensorId;
      if (!sensorId || !summaries[sensorId]) {
        return;
      }

      const summaryEntries = Object.entries(summaries[sensorId]);
      for (let [timestamp, summary] of summaryEntries) {
        timestamp = parseInt(timestamp);
        let hour = new Date(timestamp);
        let day = new Date(timestamp);
        day.setHours(0);
        let week = DateUtils.getMonday(hour);
        let month = new Date(day);
        month.setDate(1);
        let year = new Date(month);
        year.setMonth(0);

        hour = hour.getTime();
        day = day.getTime();
        week = week.getTime();
        month = month.getTime();
        year = year.getTime();

        expressionVariable.data.hour[hour] = expressionVariable.data.hour[hour] || 0;
        expressionVariable.data.day[day] = expressionVariable.data.day[day] || 0;
        expressionVariable.data.week[week] = expressionVariable.data.week[week] || 0;
        expressionVariable.data.month[month] = expressionVariable.data.month[month] || 0;
        expressionVariable.data.year[year] = expressionVariable.data.year[year] || 0;

        const path = expressionVariable.path.join('.');
        let value = utils.getObjectValue(summary, path);
        if (utils.isValidNumber(value)) {
          expressionVariable.data.hour[hour] += value;
          expressionVariable.data.day[day] += value;
          expressionVariable.data.week[week] += value;
          expressionVariable.data.month[month] += value;
          expressionVariable.data.year[year] += value;
        }
      }
    }

    function getVirtualExpressionsSolved(virtualExpression, options) {
      let from = utils.isDate(options.from) ? options.from.getTime() : options.from;
      let to = utils.isDate(options.to) ? options.to.getTime() : options.to;
      let data = (virtualExpression.data = virtualExpression.data || {
        hour: {},
        day: {},
        week: {},
        month: {},
        year: {},
      });

      const expression = math.compile(virtualExpression.expression);
      const virtualVariables = utils.arrayToObject(virtualExpression.virtualVariables, 'id');

      for (let timestamp = from; timestamp < to; timestamp += 60 * 60 * 1000) {
        const date = new Date(parseInt(timestamp));
        const hour = date.getTime();
        const day = new Date(date.getTime());
        day.setHours(0);
        const week = DateUtils.getMonday(hour);
        const month = new Date(day);
        month.setDate(1);
        const year = new Date(month);
        year.setMonth(0);

        let dateGroup = {
          hour: hour,
          day: day.getTime(),
          week: week.getTime(),
          month: month.getTime(),
          year: year.getTime(),
        };

        for (let [group, groupedData] of Object.entries(data)) {
          if (utils.isValidNumber(groupedData[dateGroup[group]])) {
            continue;
          }
          try {
            let scope = {};

            for (let expressionVariableId in virtualExpression.variables) {
              let expressionVariable = virtualExpression.variables[expressionVariableId];

              if (
                !expressionVariable ||
                !virtualVariables[expressionVariable.variable] ||
                !expressionVariable.data ||
                !expressionVariable.data[group]
              ) {
                continue;
              }

              const virtualVariable = virtualVariables[expressionVariable.variable];

              let value = expressionVariable.data[group][dateGroup[group]];
              if (value == null) {
                value = virtualVariable.value;
              }

              if (utils.isValidNumber(value)) {
                scope[expressionVariableId] = value;
              }
            }

            if (Object.keys(scope).length === Object.keys(virtualExpression.variables).length) {
              let result = expression.evaluate(scope);
              if (utils.isValidNumber(result)) {
                groupedData[dateGroup[group]] = result;
              }
            }
          } catch (err) {
            console.error(err);
          }
        }
      }
    }
  }
})();
