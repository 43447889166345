(() => {
  ExportService.$inject = ['$q', '$timeout', 'AppUtils'];

  window.angular.module('commonServices').service('ExportUtilsService', ExportService);

  function ExportService($q, $timeout, utils) {
    return {
      elementToImage,
      elementToPDF,
      generateExcel,
    };

    function elementToImage(element, options) {
      options = Object.assign(
        {
          // foreignObjectRendering: true,
          allowTaint: true,
          scale: 1,
          scrollY: 0,
          useCORS: true,
          letterRendering: true,
        },
        options
      );

      return utils.Promise((resolve, reject) => {
        html2canvas(element, options)
          .then((canvas) => {
            canvas.getContext('2d');
            resolve({
              width: canvas.width,
              height: canvas.height,
              content: canvas.toDataURL('image/jpg'),
            });
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }

    function elementToImage2(element, options) {
      options = Object.assign(
        {
          // foreignObjectRendering: true,
          filter: (node) => {
            return (
              !node.dataset ||
              (node.dataset.html2canvasIgnore === undefined && node.tagName !== 'SPINNER')
            );
          },
          pixelRatio: 1,
          backgroundColor: '#fff',
          quality: 0.9,
        },
        options
      );

      return utils.Promise((resolve, reject) => {
        htmlToImage
          .toJpeg(element, options)
          .then((dataUrl) => {
            const image = new Image();
            image.onload = () => {
              resolve({
                width: image.width,
                height: image.height,
                content: dataUrl,
              });
            };

            image.src = dataUrl;
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }

    function elementToPDF(element, options) {
      let elWidth = element.offsetWidth;
      let elHeight = element.offsetHeight;
      const defaults = {
        margin: {
          top: Math.round(elWidth * 0.05),
          right: Math.round(elWidth * 0.05),
          bottom: Math.round(elWidth * 0.05),
          left: Math.round(elWidth * 0.05),
        },
      };
      options = Object.assign(defaults, options);

      let pdfWidth = elWidth + options.margin.left + options.margin.right;
      const pdfHeight = Math.round(pdfWidth * 1.29);
      const bodyHeight = pdfHeight - options.margin.top - options.margin.bottom;
      const pdf = new jsPDF({
        unit: 'pt',
        format: [pdfWidth, pdfHeight],
        //format: [21.5, 32.5],
        compress: true,
      });

      let sections = Array.from(element.getElementsByTagName('SECTION'));
      if (sections.length) {
        let promises = [];
        for (let section of sections) {
          promises.push({
            function: elementToImage,
            args: [section],
          });
        }

        const chunks = utils.arrayToChunks(promises, 2);

        console.time('toPDF');
        return utils
          .inOrderPromises(
            chunks.map((c) => {
              return { function: utils.parallelPromises, args: [c] };
            })
          )
          .then((result) => {
            result = result.success.reduce(
              (obj, c) => {
                obj.success = [...obj.success, ...c.success];
                obj.errors = [...obj.errors, ...c.errors];
                return obj;
              },
              { errors: [], success: [] }
            );

            let offsetTop = options.margin.top;
            const promises = [];
            for (let i = 0; i < result.success.length; i++) {
              const image = result.success[i];
              if (sections[i].classList.contains('export-exclude')) {
                continue;
              }
              const pages = Math.ceil(image.height / bodyHeight);
              if (pages > 1) {
                for (let j = 0; j < pages; j++) {
                  const pos = Math.floor(bodyHeight * j);
                  let height = bodyHeight;
                  offsetTop = options.margin.top;

                  if (pos + bodyHeight > image.height) {
                    height = image.height - pos;
                  }

                  promises.push({
                    function: addToPdf,
                    args: [
                      pdf,
                      {
                        addPage: true,
                        image: image,
                        crop: { sx: 0, sy: pos + 1, width: image.width, height: height },
                        position: {
                          top: offsetTop,
                          left: options.margin.left,
                          width: image.width,
                          height: height,
                        },
                      },
                      {
                        width: pdfWidth,
                        height: pdfHeight,
                      },
                    ],
                  });
                  offsetTop = options.margin.top + height;
                }
              } else {
                let top = offsetTop;
                let addPage = false;
                if (offsetTop + image.height > bodyHeight) {
                  top = options.margin.top;
                  addPage = true;
                  offsetTop = options.margin.top + image.height;
                } else {
                  offsetTop += image.height;
                }
                promises.push({
                  function: addToPdf,
                  args: [
                    pdf,
                    {
                      addPage: addPage,
                      image: image,
                      position: {
                        top: top,
                        left: options.margin.left,
                        width: image.width,
                        height: image.height,
                      },
                    },
                    {
                      width: pdfWidth,
                      height: pdfHeight,
                    },
                  ],
                });
              }
            }

            return promises;
          })
          .then((promises) => {
            return utils.inOrderPromises(promises);
          })
          .then((result) => {
            console.timeEnd('toPDF');
            return pdf;
          });
      }

      const pdfPages = Math.ceil(elHeight / pdfHeight) - 1;
      return elementToImage(element).then((image) => {
        pdf.addImage(
          image.content,
          'jpg',
          options.margin.left,
          options.margin.top,
          elWidth,
          elHeight,
          '',
          'MEDIUM'
        );
        for (let i = 1; i <= pdfPages; i++) {
          pdf.addPage(pdfWidth, pdfHeight);
          pdf.addImage(
            image.content,
            'jpg',
            options.margin.left,
            -(pdfHeight * i) + 2 * (options.margin.top + options.margin.bottom),
            elWidth,
            elHeight,
            '',
            'MEDIUM'
          );
        }
        return pdf;
      });
    }

    function cropBase64(base64, sx, sy, width, height) {
      return $q((resolve) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const image = new Image();
        image.src = base64;
        image.onload = function () {
          canvas.getContext('2d').drawImage(image, sx, sy, width, height, 0, 0, width, height);
          resolve(canvas.toDataURL());
        };
      });
    }

    function addToPdf(pdf, config, pageConfig) {
      return $q((resolve) => {
        if (config.addPage) {
          pdf.addPage(pageConfig.width, pageConfig.height);
        }

        if (config.crop) {
          cropBase64(
            config.image.content,
            config.crop.sx,
            config.crop.sy,
            config.crop.width,
            config.crop.height
          )
            .then((cropped) => {
              pdf.addImage(
                cropped,
                'jpg',
                config.position.left,
                config.position.top,
                config.position.width,
                config.position.height,
                undefined,
                'FAST'
              );
              resolve('ok');
            })
            .catch((err) => {
              console.log(err);
              resolve('error');
            });
        } else {
          pdf.addImage(
            config.image.content,
            'jpg',
            config.position.left,
            config.position.top,
            config.position.width,
            config.position.height,
            undefined,
            'FAST'
          );
          resolve('ok');
        }
      });
    }

    function generateExcel(fileName, sheets) {
      const wb = new ExcelJS.Workbook();
      for (let _sheet of sheets) {
        const data = _sheet.data;
        const sheet = wb.addWorksheet(_sheet.name);
        data.forEach((current, index) => {
          sheet.addRow(current);
        });

        /* 
        // This code is to set the width of the columns based on the data. Too slow
        for (let i = 0; i < sheet.columns.length; i += 1) {
          let dataMax = 0;
          const column = sheet.columns[i];
          for (let j = 1; j < column.values.length; j += 1) {
            const columnLength = column.values[j] == null ? 0 : column.values[j].length;
            if (columnLength > dataMax) {
              dataMax = columnLength;
            }
          }
          column.width = dataMax < 10 ? 10 : dataMax;
        } */
      }
      const defer = $q.defer();
      wb.xlsx
        .writeBuffer()
        .then(function (data) {
          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = fileName + '.xlsx';
          anchor.click();
          window.URL.revokeObjectURL(url);
          defer.resolve();
        })
        .catch((err) => {
          defer.reject(err);
        });

      return defer.promise;
    }
  }
})();
