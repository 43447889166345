(() => {
  angular.module('commonServices').service('DrawUtils', Service);

  function Service() {
    return {
      getPolygonCentroid,
    };

    function getPolygonCentroid(points) {
      let first = points[0];
      let last = points[points.length - 1];
      if (first.x != last.x || first.y != last.y) {
        points.push(first);
      }

      let twiceArea = 0;
      let x = 0;
      let y = 0;
      let nPoints = points.length;
      let p1, p2, f;

      for (var i = 0, j = nPoints - 1; i < nPoints; j = i++) {
        p1 = points[i];
        p2 = points[j];
        f = (p1.y - first.y) * (p2.x - first.x) - (p2.y - first.y) * (p1.x - first.x);
        twiceArea += f;
        x += (p1.x + p2.x - 2 * first.x) * f;
        y += (p1.y + p2.y - 2 * first.y) * f;
      }
      f = twiceArea * 3;
      return { x: x / f + first.x, y: y / f + first.y };
    }
  }
})();
