; (() => {

  Service.$inject = [
    '$rootScope',
    'AppUtils',
    'Customer',
    'GlobalEventState',
    '$translate',
  ];

  angular
    .module('commonServices')
    .service('EventStateService', Service);

  function Service(
    $root,
    utils,
    Customer,
    GlobalEventState,
    $translate) {

    let defaultComments;
    let eventStates;
    let globalEventStates;

    return {
      loadDefaultComments: loadDefaultComments,
      loadEventStates: loadEventStates,
      loadGlobalEventStates: loadGlobalEventStates,
      getMergedEventStates: getMergedEventStates,
    };

    function loadDefaultComments(force) {
      if (defaultComments && !force) {
        return utils.Promise.resolve(angular.copy(defaultComments));
      }
      return Customer
        .prototype$__get__defaultEventComments({
          id: $root.customerId,
          filter: { fields: ['id', 'code', 'content'], order: 'code ASC' },
        })
        .$promise
        .then(comments => {
          defaultComments = comments;
          return angular.copy(comments);
        })
        .catch(err => {
          return [];
        });
    }

    function loadEventStates(force) {
      if (eventStates && !force) {
        return utils.Promise.resolve(angular.copy(eventStates));
      }
      return Customer
        .prototype$__get__eventStates({
          id: $root.customerId,
          filter: { fields: ['id', 'name', 'type', 'colors', 'globalEventStateId'], order: 'name ASC' },
        })
        .$promise
        .then(states => {
          states.forEach(current => {
            if (!current.colors) {
              current.colors = { background: current.color || '#333333' };
            }
            current.colors.text = current.colors.text || utils.getTextColor(current.colors.background);
            current.colors.border = current.colors.border || current.colors.background;
          });
          eventStates = states;
          return angular.copy(states);
        })
        .catch(err => {
          return [];
        });
    }

    function loadGlobalEventStates(force) {
      if (globalEventStates && !force) {
        return utils.Promise.resolve(angular.copy(globalEventStates));
      }
      return GlobalEventState
        .find()
        .$promise
        .then(states => {
          states.forEach(current => {
            if (!current.colors) {
              current.colors = { background: current.color || '#333333' };
            }
            current.colors.text = current.colors.text || utils.getTextColor(current.colors.background);
            current.colors.border = current.colors.border || current.colors.background;
            current.name = $translate.instant('entities.event.__states.' + current.type);
          });
          globalEventStates = states;
          return angular.copy(states);
        })
        .catch(err => {
          return [];
        });
    }

    function getMergedEventStates(force) {
      let globalEventStates;
      return loadGlobalEventStates(force)
        .then(result => {
          globalEventStates = angular.copy(result);
          return loadEventStates(force);
        })
        .then(result => {
          result = angular.copy(result);
          const notFound = globalEventStates.filter(state => !result.find(c => c.type === state.type));
          Array.prototype.push.apply(result, notFound);
          return result;
        });
    }
  }
})();
