(() => {
  const angular = window.angular;

  Service.$inject = ['AppUtils', 'ObjectRecognitionService', '$translate'];

  angular.module('commonServices').service('ChartDataService', Service);

  function Service(utils, ObjectRecognitionService, $translate) {
    const classes = utils.arrayToObject(ObjectRecognitionService.getClasses(), 'value');
    const poses = utils.arrayToObject(ObjectRecognitionService.getPoses(), 'value');

    return {
      getTimeSeriesData,
    };

    function getTimeSeriesData(sensor, data) {
      const method = {
        FaceDetection,
        FaceMaskDetection: FaceDetection,
        NaiveSocialDistancing: ObjectRecognition,
        GranularityDetection: GranularityDetection,
        Number,
        ObjectCounting,
        PeriodicObjectCounting: ObjectCounting,
        ObjectRecognition,
        ObjectRecognitionNumeric,
        PoseDetection,
        SpeedChange: ObjectRecognition,
        StoppedObjectsDetector: ObjectRecognition,
      };

      if (!method[sensor.type]) {
        throw 'SensorType not implemented';
      }

      return method[sensor.type](sensor, data);
    }

    function Number(sensor, data) {
      let values = [],
        sum = 0,
        len = 0,
        min = null,
        max = null;
      data.forEach((current) => {
        let pair = { timestamp: current.from.getTime(), value: current.content };

        if (values.length && values[values.length - 1][0] === pair.timestamp) {
          return;
        }

        len++;
        sum += pair.value;
        min = min === null || min > pair.value ? pair.value : min;
        max = max === null || max < pair.value ? pair.value : max;
        values.push([pair.timestamp, pair.value]);
      });

      let series = {};
      series.name = sensor.name;
      series.data = values;
      return {
        min: min,
        max: max,
        average: len === 0 ? null : sum / len,
        series: [series],
        last: values.length > 0 ? values[values.length - 1] : null,
      };
    }

    function ObjectCounting(sensor, data) {
      let series = {};
      data.forEach((current) => {
        for (let _class in current.content) {
          if (!series[_class]) {
            series[_class] = {};
            series[_class].name = classes[_class].label;
            series[_class].data = [];
          }
          const values = series[_class].data;
          const count = current.content[_class];
          values.push([current.from.getTime(), count.length]);
        }
      });
      series = Object.values(series);
      return {
        series: series.length ? series : [{ data: [] }],
      };
    }

    function ObjectRecognition(sensor, data) {
      let series = {};
      const summaries = {};
      data.forEach((current) => {
        const content = current.content;
        const detections = {};
        for (let detection of content.objects) {
          const _class = detection.class;
          if (!classes[_class]) {
            continue;
          }
          detections[_class] = detections[_class] ? detections[_class] + 1 : 1;
          summaries[_class] = summaries[_class] ? summaries[_class] + 1 : 1;
        }

        for (const _class in detections) {
          if (!series[_class]) {
            series[_class] = { name: classes[_class].label, data: [], type: 'bar', stack: 'stack' };
          }

          const values = series[_class].data;
          values.push([current.from.getTime(), detections[_class]]);
        }
      });
      series = Object.values(series);
      series.sort((a, b) => (a.name > b.name ? 1 : -1));
      return {
        series: series.length ? series : [{ data: [] }],
        options: { legend: {}, grid: { left: 10, top: 30, right: 10, bottom: 37 } },
        summaries: summaries,
      };
    }

    function ObjectRecognitionNumeric(sensor, data) {
      let series = {};
      const summaries = {};
      data.forEach((current) => {
        const content = current.content;
        const detections = {};
        for (let detection of content.objects) {
          const _class = detection.class;
          const value = detection.value;
          if (!classes[_class] || isNaN(value)) {
            continue;
          }
          detections[_class] = detections[_class] ? detections[_class] + value : value;
          summaries[_class] = summaries[_class] ? summaries[_class] + value : value;
        }

        for (const _class in detections) {
          if (!series[_class]) {
            series[_class] = { name: classes[_class].label, data: [] };
          }

          const values = series[_class].data;
          values.push([current.from.getTime(), detections[_class]]);
        }
      });
      series = Object.values(series);
      series.sort((a, b) => (a.name > b.name ? 1 : -1));
      return {
        series: series.length ? series : [{ data: [] }],
        options: { legend: {}, grid: { left: 10, top: 30, right: 10, bottom: 37 } },
        summaries: summaries,
      };
    }

    function GranularityDetection(sensor, data) {
      let series = {};
      const summaries = {};
      data.forEach((current) => {
        const content = current.content;
        const detections = {};
        for (let detection of content.objects) {
          const _class = detection.class;
          const area = detection.area;
          const diameter = detection.diameter;
          if (!classes[_class] || isNaN(area) || isNaN(diameter)) {
            continue;
          }
          detections['area'] = detections['area'] ? detections['area'] + area : area;
          detections['diameter'] = detections['diameter']
            ? detections['diameter'] + diameter
            : diameter;
          summaries['area'] = summaries['area'] ? summaries['area'] + area : area;
          summaries['diameter'] = summaries['diameter']
            ? summaries['diameter'] + diameter
            : diameter;
        }

        for (const _class in detections) {
          if (!series[_class]) {
            series[_class] = { name: classes[_class].label, data: [] };
          }

          const values = series[_class].data;
          values.push([current.from.getTime(), detections[_class]]);
        }
      });
      series = Object.values(series);
      series.sort((a, b) => (a.name > b.name ? 1 : -1));
      return {
        series: series.length ? series : [{ data: [] }],
        options: { legend: {}, grid: { left: 10, top: 30, right: 10, bottom: 37 } },
        summaries: summaries,
      };
    }

    function PoseDetection(sensor, data) {
      let series = {};
      const summaries = {};
      data.forEach((current) => {
        const content = current.content;
        const detections = {};

        for (let detection of content.poses) {
          const label = detection.label.toLowerCase();
          if (!poses[label]) {
            continue;
          }

          detections[label] = detections[label] ? detections[label] + 1 : 1;
          summaries[label] = summaries[label] ? summaries[label] + 1 : 1;
        }

        for (const label in detections) {
          if (!series[label]) {
            series[label] = { name: poses[label].label, data: [], type: 'bar', stack: 'stack' };
          }

          const values = series[label].data;
          values.push([current.from.getTime(), detections[label]]);
        }
      });
      series = Object.values(series);
      series.sort((a, b) => (a.name > b.name ? 1 : -1));

      return {
        series: series.length ? series : [{ data: [] }],
        options: { legend: {}, grid: { left: 10, top: 30, right: 10, bottom: 37 } },
        summaries: summaries,
      };
    }

    function FaceDetection(sensor, data) {
      let series = [
        {
          name: $translate.instant('entities.sensor.__classes.face'),
          data: [],
        },
      ];

      if (sensor.type === 'FaceMaskDetection') {
        series.push({
          name: $translate.instant('entities.sensor.__classes.mask'),
          data: [],
        });
      }

      data.forEach((current) => {
        const faces = current.content.faces || [];
        const timestamp = current.from.getTime();
        series[0].data.push([timestamp, faces.length]);

        if (series[1]) {
          const maskCount = faces.reduce(
            (sum, current) => (current.mask && current.mask.probability ? sum + 1 : sum),
            0
          );
          series[1].data.push([timestamp, maskCount]);
        }
      });

      return { series };
    }
  }
})();
