(() => {
  const angular = window.angular;

  angular.module('commonServices').service('NumberUtils', NumberUtils);

  NumberUtils.$inject = [];

  function NumberUtils() {
    return {
      isValidNumber: isValidNumber,
      metricPrefix: metricPrefix,
    };

    function metricPrefix(value) {
      if (!isValidNumber(value)) {
        return;
      }

      var ranges = [
        { divider: 1e18, suffix: 'E' },
        { divider: 1e15, suffix: 'P' },
        { divider: 1e12, suffix: 'T' },
        { divider: 1e9, suffix: 'G' },
        { divider: 1e6, suffix: 'M' },
        { divider: 1e3, suffix: 'k' },
      ];

      for (var i = 0; i < ranges.length; i++) {
        if (value >= ranges[i].divider) {
          return (value / ranges[i].divider).toString() + ranges[i].suffix;
        }
      }
      return value.toString();
    }

    function isValidNumber(number, options) {
      const defaultOptions = {
        strict: true,
        finite: true,
        parse: false,
      };

      options = angular.extend(defaultOptions, options || {});

      if (typeof number !== 'number' && typeof number !== 'string') {
        return false;
      }

      if (options.strict) {
        return (
          typeof number === 'number' &&
          (!options.finite || (options.finite && Number.isFinite(number)))
        );
      } else {
        if (options.parse) {
          number = parseFloat(number);
        }
        return options.finite ? Number.isFinite(number) : isNaN(number);
      }
    }
  }
})();
