;(() => {

  ChartCategories.$inject = ['$translate', 'DateUtils'];

  window.angular
    .module('commonServices')
    .service('ChartCategoriesService', ChartCategories);

  function ChartCategories($translate, DateUtils) {
    return {
      hours,
      days,
      monthDays,
      months,
      categoriesFromDates,
    };

    function categoriesFromDates(from, to, mode) {
      const nDays = DateUtils.daysBetween(from, to);
      mode = mode || 'day';
      let categories = [];

      if (nDays > 0 && mode === 'day') {
        let days = DateUtils.allDaysBetween(from, to);
        for (let day of days) {
          const date = DateUtils.pad(day.getDate());
          const month = DateUtils.pad(day.getMonth() + 1);
          let category = date + '-' + month;
          categories.push(category);
        }
      } else {
        mode = 'hour';
        if (nDays > 0) {
          for (let i = 0; i < 24; i++) {
            categories.push(DateUtils.pad(i));
          }
        } else {
          let hours = DateUtils.allHoursBetween(from, to);
          for (let hour of hours) {
            categories.push(DateUtils.pad(hour.getHours()));
          }
        }
      }

      return {mode, categories};
    }

    function hours() {
      return ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
        '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    }

    function monthDays(month, year) {
      let date = new Date();
      if (year) {
        date.setFullYear(year);
      }
      date.setMonth(month + 1);
      date.setDate(0);

      let lastDay = date.getDate();

      let days = [];
      for (let i = 1; i <= lastDay; i++) {
        days.push(i < 10 ? '0' + i : i + '');
      }

      return days;
    }

    function days(short) {
      short = typeof short === 'boolean' ? short : true;
      return [
        $translate.instant(`general._date._day.monday${short ? '_short' : ''}`),
        $translate.instant(`general._date._day.tuesday${short ? '_short' : ''}`),
        $translate.instant(`general._date._day.wednesday${short ? '_short' : ''}`),
        $translate.instant(`general._date._day.thursday${short ? '_short' : ''}`),
        $translate.instant(`general._date._day.friday${short ? '_short' : ''}`),
        $translate.instant(`general._date._day.saturday${short ? '_short' : ''}`),
        $translate.instant(`general._date._day.sunday${short ? '_short' : ''}`),
      ];
    }

    function months(short) {
      short = typeof short === 'boolean' ? short : true;
      return [
        $translate.instant(`general._date._month.january${short ? '_short' : ''}`),
        $translate.instant(`general._date._month.february${short ? '_short' : ''}`),
        $translate.instant(`general._date._month.march${short ? '_short' : ''}`),
        $translate.instant(`general._date._month.april${short ? '_short' : ''}`),
        $translate.instant(`general._date._month.may${short ? '_short' : ''}`),
        $translate.instant(`general._date._month.june${short ? '_short' : ''}`),
        $translate.instant(`general._date._month.july${short ? '_short' : ''}`),
        $translate.instant(`general._date._month.august${short ? '_short' : ''}`),
        $translate.instant(`general._date._month.september${short ? '_short' : ''}`),
        $translate.instant(`general._date._month.october${short ? '_short' : ''}`),
        $translate.instant(`general._date._month.november${short ? '_short' : ''}`),
        $translate.instant(`general._date._month.december${short ? '_short' : ''}`),
      ];
    }

  }
})();
