;(() => {
  const angular = window.angular;
  const $ = window.$;

  ProviderStatusService.$inject = ['$http'];

  angular
    .module('commonServices')
    .service('ProviderStatusService', ProviderStatusService);

  function ProviderStatusService ($http) {

    return {
      getWasabiStatus: getWasabiStatus
    };

    function getWasabiStatus () {
      $http({
        method: 'GET',
        url: 'https://status.wasabi.com/pages/5abbc12aeb57a904e44a58d3/rss',
        transformResponse: function (data) {
          data = data.replace(/<!\[CDATA\[(.*?)]]>/g, (match, content) => {
            let parts = content.split(/<br \/>|<br\/>/);
            parts = parts.filter(current => !!current);
            if (parts.length === 1) {return parts[0];}

            const items = [];
            for (let i = 0; i < parts.length; i += 2) {
              const date = parts[i].replace(/<small>(.*?)<\/small>/g, (match, content) => `<date>${content}</date>`);
              let activity = '';
              let details = '';
              if (parts[i + 1]) {
                const split = parts[i + 1].split('-');
                activity = split[0].trim().replace(/<b>(.*?)<\/b>/g, (match, content) => `<activity>${content}</activity>`);
                details = split[1] ? `<details>${split[1].trim()}</details>` : '';
              }

              items.push(`<item>${date}${activity}${details}</item>`);
            }
            return items.join('');
          });
          return xmlToJson($.parseXML(data));
        }
      })
        .then(response => {
          const data = response.data;
          console.log(data.rss.channel.item);
        })
        .catch(err => {
          console.log(err);
        });
    }

    function xmlToJson (xml) {
      // Create the return object
      let obj = {};

      if (xml.nodeType === 1) {
        // element
        // do attributes
        if (xml.attributes.length > 0) {
          obj['@attributes'] = {};
          for (let j = 0; j < xml.attributes.length; j++) {
            const attribute = xml.attributes.item(j);
            obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
          }
        }
      } else if (xml.nodeType === 3) {
        // text
        obj = xml.nodeValue;
      }

      // do children
      // If all text nodes inside, get concatenated text from them.
      const textNodes = [].slice.call(xml.childNodes).filter(function (node) {
        return node.nodeType === 3;
      });
      if (xml.hasChildNodes() && xml.childNodes.length === textNodes.length) {
        obj = [].slice.call(xml.childNodes).reduce(function (text, node) {
          return text + node.nodeValue;
        }, '');
      } else if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; i++) {
          const item = xml.childNodes.item(i);
          const nodeName = item.nodeName;
          if (typeof obj[nodeName] == 'undefined') {
            obj[nodeName] = xmlToJson(item);
          } else {
            if (typeof obj[nodeName].push == 'undefined') {
              const old = obj[nodeName];
              obj[nodeName] = [];
              obj[nodeName].push(old);
            }
            obj[nodeName].push(xmlToJson(item));
          }
        }
      }
      return obj;
    }
  }

})();
