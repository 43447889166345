(function () {
  'use strict';

  let angular = window.angular;
  const MIN_INTERVAL = 5;
  SensorService.$inject = [
    '$rootScope',
    'Customer',
    'Sensor',
    'ObjectRecognitionUtilsService',
    '$q',
    'DateUtils',
    'AppUtils',
    'ObjectRecognitionService',
    'LoopBackAuth',
  ];

  angular.module('commonServices').service('SensorService', SensorService);

  function SensorService(
    $root,
    Customer,
    Sensor,
    ObjectRecognitionUtilsService,
    $q,
    DateUtils,
    utils,
    ObjectRecognitionService,
    LoopBackAuth
  ) {
    let service = {
      //SensorAssetState
      SAS: {},
    };

    return {
      findById: findById,
      getDetectorClasses: getDetectorClasses,
      getDetectionCentroid: ObjectRecognitionUtilsService.getDetectionCentroid,
      getImageUrl: getImageUrl,
      getFileUrl,
      getDataImage: getDataImage,
      getLastData: getLastData,
      getMultipleLastData: getMultipleLastData,
      getData: getData,
      getSummaries: getSummaries,
      getThreshold: getThreshold,
      getSensorAssetState: getSensorAssetState,
      getNumberValue: getNumberValue,
      getSensorAccuracy: getSensorAccuracy,
      getRelatedTriggers: getRelatedTriggers,
      prepareSensorSummaries: prepareSummaries,
      getSummaryInterval: getSummaryInterval,
      getSensorAssetStates: getSensorAssetStates,
    };

    function getDetectorClasses() {
      return ObjectRecognitionService.getClasses();
    }

    function getDataImage(sensorId, dataContent, from) {
      if (!dataContent) {
        return;
      }
      let image = dataContent.files ? dataContent.files.image : dataContent.image;
      image = Array.isArray(image) ? image[0] : image;

      if (!image || !image.container || !image.name) {
        return;
      }

      return getImageUrl(sensorId, image, from);
    }

    function getImageUrl(sensorId, file, from) {
      if (from && typeof from === 'string') {
        from = new Date(from);
      }
      const limit = new Date('2021-06-17T00:00:00.000Z');
      const test = from && from <= limit;

      let apiPath = window.apiPath;
      let accessToken = LoopBackAuth.accessTokenId;
      if (window.imageFromProd && window.environment !== 'production' && test) {
        apiPath = 'https://api.xompass.com/api';
        accessToken = window.localStorage.getItem('$LoopBack$accessTokenId2');
      }

      // apiPath = 'https://api.xompass.com/api';
      let url = `${apiPath}/sensors/${sensorId}/datasets/${file.container}/download/${file.name}?access_token=${accessToken}`;
      if (file.datasourceName) {
        url += '&datasourceName=' + file.datasourceName;
      }

      if (window.useStorageSignedUrl) {
        url += '&signed-url=true';
      }

      return url;
    }

    function getFileUrl(sensorId, file) {
      let url = getImageUrl(sensorId, file);
      if (!window.useStorageSignedUrl) {
        url += '&signed-url=true';
      }

      return url;
    }

    function findById(sensorId, filter) {
      filter = filter || {};
      filter.where = { id: sensorId };

      return Sensor.find({
        id: sensorId,
        filter: filter,
      })
        .$promise.then((result) => {
          if (!result.length) {
            const err = new Error('Not Found');
            err.code = 'NOT_FOUND';
            err.statusCode = 404;
            throw err;
          }
          return result[0];
        })
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    /**
     * @param sensorId {string}
     * @return {Promise}
     */
    function getLastData(sensorId) {
      return Sensor.prototype$getLastData({
        id: sensorId,
      }).$promise.catch((err) => {
        throw utils.getHTTPError(err);
      });
    }

    /**
     * @param customerId {string}
     * @param projectId {string}
     * @param assetId {string}
     * @param [sensorIds] {string}
     * @return {Promise}
     */
    function getMultipleLastData(customerId, projectId, assetId, sensorIds) {
      let defer = $q.defer();
      Customer.assets({
        id: customerId,
        filter: { id: assetId },
        include: { relation: 'sensors', scope: { fields: ['id', '_lastData'] } },
        fields: ['id'],
      })
        .$promise.then((assets) => {
          return assets[0];
        })
        .then((asset) => {
          if (!asset) {
            const error = new Error('ASSET NOT FOUND');
            error.statusCode = 404;
            throw error;
          }
          asset.sensors = asset.sensors || [];
          let response = {};
          asset.sensors.forEach((current) => {
            response[current.id] = current._lastData;
          });

          defer.resolve(response);
        })
        .catch((err) => {
          err = utils.getHTTPError(err);
          defer.reject(err);
        });

      return defer.promise;
    }

    /**
     * @param sensorId {string}
     * @param options
     * @param options.filter {object} It will be sent as filter, from and to will be overwritten
     * @param options.from {date} The start date of query
     * @param options.to {date} The end date of query
     * @return {Promise}
     */
    function getData(sensorId, options) {
      let filter = {};
      if (typeof options.filter === 'object') {
        filter = options.filter;
      }

      let from = options.from;
      let to = options.to;

      if (!(from instanceof Date)) {
        from = new Date();
        from.setDate(from.getDate() - 1);
      }

      if (!(to instanceof Date)) {
        to = new Date();
      }

      let userFrom = new Date(from);

      from = new Date(from.getTime());
      let minutes = from.getMinutes();
      from.setMinutes(minutes - (minutes % 5));
      from.setSeconds(0, 0);

      filter.where = {
        and: [{ testMode: false }, { from: { gte: from } }, { from: { lt: to } }],
      };

      filter.fields = filter.fields || ['_data.content', '_data.id', '_data.from', 'from', 'to'];
      filter.order = filter.order || 'from DESC';

      let defer = $q.defer();
      Sensor.prototype$__get__datasets({
        id: sensorId,
        filter: filter,
      })
        .$promise.then((dataSets) => {
          defer.resolve(mergeAndFilterDataSets(dataSets, userFrom, to));
          /*let worker = Webworker.create()

          return worker.run(dataSets, from, to)*/
        })
        .catch((err) => {
          err = utils.getHTTPError(err);
          defer.reject(err);
        });

      return defer.promise;
    }

    function getSummaryInterval(from, to) {
      if (!utils.isDate(from)) {
        from = new Date(from);
      }
      if (!utils.isDate(to)) {
        to = new Date(to);
      }
      if (!utils.isDate(from) || !utils.isDate(to)) {
        throw new Error('Invalid date range');
      }

      from = new Date(from.getTime());
      to = new Date(to.getTime());

      from.setHours(0, 0, 0);
      const offset = from.getTimezoneOffset();
      from.setMinutes(from.getMinutes() - offset);

      to.setMinutes(59, 59, 999);
      return [from, to];
    }

    function prepareSummaries(summaries) {
      const result = [];
      summaries.forEach((current) => {
        current.from = new Date(current.from);
        current.to = new Date(current.to);
        Object.assign(current, current.detail);
        delete current.detail;
        result.push(current);
      });

      return result;
    }

    /**
     *
     * @param sensorId
     * @param from
     * @param to
     * @param [filter]
     * @returns {Promise<any>}
     */
    function getSummaries(sensorId, from, to, filter) {
      if (!utils.isDate(from)) {
        from = new Date(from);
      }
      if (!utils.isDate(to)) {
        to = new Date(to);
      }

      from = new Date(from);
      to = new Date(to);
      from.setMinutes(0, 0, 0);
      to.setMinutes(0, 0, 0);

      filter = filter || {};
      filter.where = filter.where ? { and: [filter.where] } : { and: [] };

      filter.where.and.push({ from: { gte: from } });
      filter.where.and.push({ from: { lt: to } });

      filter.fields = filter.fields || ['detail', 'from', 'to', 'sensorId'];
      filter.order = filter.order || 'from DESC';

      return Sensor.prototype$__get__summaries({
        id: sensorId,
        filter: filter,
      })
        .$promise.then((summaries) => {
          return prepareSummaries(summaries);
        })
        .catch((err) => {
          err = utils.getHTTPError(err);
          throw err;
        });
    }

    function getNumberValue(data, sensorType) {
      const validTypes = {
        Number: 'content',
      };

      if (Object.keys(validTypes).indexOf(sensorType) === -1) {
        return;
      }

      return utils.getObjectValue(data, validTypes[sensorType]);
    }

    function mergeAndFilterDataSets(dataSets, from, to) {
      let merged = [];
      dataSets.sort((a, b) => {
        const aFrom = new Date(a.from);
        const bFrom = new Date(b.from);
        return aFrom.getTime() - bFrom.getTime();
      });

      dataSets.forEach((dataSet) => {
        if (!dataSet._data) {
          return;
        }
        if (dataSet._data.length === 1) {
          dataSet._data[0].from = new Date(dataSet._data[0].from);
        } else {
          dataSet._data.sort((a, b) => {
            a.from = a.from.getTime ? a.from : new Date(a.from);
            b.from = b.from.getTime ? b.from : new Date(b.from);

            return a.from.getTime() - b.from.getTime();
          });
        }
        Array.prototype.push.apply(merged, dataSet._data);
      });

      let filteredMerged = merged.filter((data) => data.from >= from && data.from < to);

      return filteredMerged;

      /*merged.sort((a, b) => {
        return a.from.getTime() - b.from.getTime();
      });*/

      // return merged;
    }

    /**
     *
     * @param sensor {object}
     * @param assetState {string}
     * @param value {number}
     * @return {Promise}
     */
    function getThreshold(sensor, assetState, value) {
      if (!sensor || !assetState || isNaN(parseFloat(value))) {
        return $q.resolve();
      }

      return getSensorAssetState(sensor, assetState).then((state) => {
        if (!state || state.thresholdType === 'NONE' || !Array.isArray(state.thresholds)) {
          return;
        }
        return state.thresholds.find(function (current) {
          let props = current.properties || current;
          let min = isNaN(props.min) ? null : props.min;
          let max = isNaN(props.max) ? null : props.max;

          if (min !== null && max !== null) {
            if (value < max && min <= value) {
              return true;
            }
          } else if (min !== null) {
            if (value >= min) {
              return true;
            }
          } else if (max !== null) {
            if (max > value) {
              return true;
            }
          }
        });
      });
    }

    function getSensorAssetState(sensor, assetState) {
      if (!sensor || !assetState) {
        return $q.resolve();
      }

      //force reload sensorAssetStated after 8-10 minutes
      let interval = Math.floor(Math.random() * (600000 - 480000) + 480000);

      if (
        service.SAS[sensor.id] &&
        service.SAS[sensor.id][assetState] &&
        service.SAS[sensor.id][assetState].update > new Date() - interval
      ) {
        return $q.resolve(service.SAS[sensor.id][assetState].state);
      }

      return Sensor.states({
        id: sensor.id,
        filter: {
          where: { assetStateId: assetState },
        },
      })
        .$promise.then((state) => {
          state = state.length ? state[0] : null;
          service.SAS[sensor.id] = service.SAS[sensor.id] || {};
          service.SAS[sensor.id][assetState] = {
            state: state,
            update: new Date().getTime(),
          };

          return state;
        })
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function getSensorAssetStates(sensorId, assetId) {
      if (!sensorId || !assetId) {
        return $q.resolve();
      }

      //force reload sensorAssetStated after 8-10 minutes
      let interval = Math.floor(Math.random() * (600000 - 480000) + 480000);

      if (
        service.SAS[sensorId] &&
        service.SAS[sensorId]['assetStates'] &&
        service.SAS[sensorId]['assetStates'].update > new Date() - interval
      ) {
        return $q.resolve(service.SAS[sensorId]['assetStates'].states);
      }

      return Customer.prototype$__get__assets__assetStates({
        id: $root.customerId,
        nk: assetId,
        filter: {
          fields: ['id'],
        },
      })
        .$promise.then((states) => {
          return Sensor.states({
            id: sensorId,
            where: {
              assetStateId: {
                inq: states.map((state) => state.id),
              },
            },
          }).$promise;
        })
        .then((states) => {
          service.SAS[sensorId] = service.SAS[sensorId] || {};
          service.SAS[sensorId]['assetStates'] = {
            states: states,
            update: new Date().getTime(),
          };

          return states;
        })
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function getSensorAccuracy(sensor) {
      if (!sensor) {
        return;
      }
      return sensor.parameters ? sensor.parameters.accuracy || 1 : 2;
    }

    function getRelatedTriggers(sensorId, eventTriggers, key = 'sensorId') {
      const statesKey = key === 'sensorId' ? 'states' : 'stateTemplates';
      const rulesKey = key === 'sensorId' ? '_rules' : '_ruleTemplates';
      return eventTriggers.filter((current) => {
        if (current.type !== 'BasedOnRules') {
          return;
        }

        let rule = null;
        if (current[statesKey]) {
          for (let state of current[statesKey]) {
            rule = state[rulesKey].find((rule) => {
              return rule.clauses.find((clause) => clause[key] === sensorId);
            });

            if (rule) {
              break;
            }
          }
        } else {
          rule = current[rulesKey].find((rule) => {
            return rule.clauses.find((clause) => clause[key] === sensorId);
          });
        }

        return !!rule;
      });
    }
  }
})();
