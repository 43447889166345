(function () {
  'use strict';
  const angular = window.angular;

  AssetWizardTypeService.$inject = ['$interpolate'];

  angular.module('commonServices').service('AssetWizardTypeService', AssetWizardTypeService);

  function AssetWizardTypeService($interpolate) {
    return {
      hasAssetWizard: hasAssetWizard,
      getSettingsComponent: getSettingsComponent,
      getSettingsDetailsComponent: getSettingsDetailsComponent,
      interpolateTemplate: interpolateTemplate,
      getDefaultWizardValues: getDefaultWizardValues,
    };

    function interpolateTemplate(template, data) {
      if (typeof template === 'object') {
        template = JSON.stringify(template);
      }

      let templateStr = template.replace(/"bind::(.*?)"/g, (match, content) => content);
      templateStr = templateStr.replace(/{{(.*?)}}/g, (match) => match.replace(/\$/g, '.'));
      templateStr = templateStr.replace(/\.config/g, '._config');

      const exp = $interpolate(templateStr);
      return JSON.parse(exp(data));
    }

    function hasAssetWizard(assetType) {
      const assetTypes = ['ObjectRecognitionCam'];

      return assetTypes.indexOf(assetType) !== -1;
    }

    function getSettingsComponent(assetType, assetWizardType) {
      let wizardTypes = {
        ObjectRecognitionCam: {
          SurveillanceNVR: 'video-cam-settings',
          SurveillanceCamera: 'surveillance-camera-settings',
          VSAASFaces: 'vsaas-faces-cam-settings',
        },
        ObjectRecognitionZone: {
          SurveillanceNVR: 'video-zone-settings',
          VSAASFaces: 'vsaas-faces-zone-settings',
        },
        Store: {
          StoreVideoAnalytics: 'store-video-zone-settings',
        },
        Generic: {},
      };
      let temp = wizardTypes[assetType] ? wizardTypes[assetType][assetWizardType] : null;
      return temp;
    }

    //funcion que obtiene el tipo de componente para mostrar los detalles del asset
    function getSettingsDetailsComponent(assetType, assetWizardType) {
      let wizardTypes = {
        ObjectRecognitionCam: {
          SurveillanceNVR: 'video-cam-settings-details',
          SurveillanceCamera: 'surveillance-camera-settings-details',
          VSAASFaces: 'vsaas-faces-cam-settings-details',
        },
        ObjectRecognitionZone: {
          SurveillanceNVR: 'video-zone-settings-details',
          VSAASFaces: 'vsaas-faces-zone-settings-details',
        },
        Store: {
          StoreVideoAnalytics: 'store-video-zone-settings-details',
        },
        Generic: {},
      };
      return wizardTypes[assetType] ? wizardTypes[assetType][assetWizardType] : null;
    }
    // esta duncion define datos por defecto para los wizardtype
    function getDefaultWizardValues(assetWizardType) {
      let defaultData = {
        'video-cam-settings-details': {
          broker: {
            host: undefined,
            port: undefined,
            namespace: undefined,
          },
          nvr: {
            channel: undefined,
          },
        },
        'surveillance-camera-settings-details': {
          camera: {
            ip: undefined,
            port: 80,
            stream: undefined,
            auth: {
              user: undefined,
              password: undefined,
            },
          },
        },
        'vsaas-faces-cam-settings-details': {
          broker: {
            host: undefined,
            port: undefined,
            namespace: undefined,
          },
          camera: {
            url: undefined,
            auth: {
              type: 'none',
              user: undefined,
              password: undefined,
            },
          },
          /* rekognition: {
            cameraId: undefined,
          }, */
        },
        'video-zone-settings-details': {
          nvr: {
            host: undefined,
            user: undefined,
            password: undefined,
            port: 80,
          },
        },
        'vsaas-faces-zone-settings-details': {
          /* rekognition: {
            storeId: undefined,
          }, */
        },
        'store-video-zone-settings-details': {
          nvr: {
            host: undefined,
            user: undefined,
            password: undefined,
            port: undefined,
          },
        },
      };
      return defaultData[assetWizardType];
    }
  }
})();
