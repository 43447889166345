(function () {
  const $ = window.$;
  const GMAP = window.google ? window.google.maps : null;
  gisGeoJSONService.$inject = ['AppUtils', 'GisUtils', '$timeout', '$http'];

  angular.module('commonServices').service('GisGeoJSONService', gisGeoJSONService);

  function gisGeoJSONService(utils, gisUtils, $timeout, $http) {
    const _this = this;
    this.coordinateToGLatLng = coordinateToGLatLng;
    this.getCenterFromCoordinates = getCenterFromCoordinates;
    this.toGeoJSON = toGeoJSON;
    this.loadGeoJSON = loadGeoJSON;
    this.layerToGeoJSON = layerToGeoJSON;
    this.getBounds = getBounds;

    function coordinateToGLatLng(coordinate) {
      if (angular.isArray(coordinate)) {
        return new GMAP.LatLng({ lat: coordinate[1], lng: coordinate[0] });
      }
      return new GMAP.LatLng({ lat: coordinate.lat, lng: coordinate.lng });
    }

    function getBounds(features) {
      if (!features || typeof features !== 'object') {
        return;
      }

      if (!Array.isArray(features)) {
        features = [features];
      }

      if (features.length === 1 && features[0].type === 'Point') {
        const feature = features[0].type === 'Feature' ? features[0].geometry : features[0];
        return new GMAP.Circle({
          radius: 5000, // 2km
          center: {
            lat: feature.coordinates[1],
            lng: feature.coordinates[0],
          },
        }).getBounds();
      }

      const bounds = new GMAP.LatLngBounds();
      for (let feature of features) {
        feature = feature.type === 'Feature' ? feature.geometry : feature;
        const type = feature.type;
        let coordinates = [];
        if (type === 'Point') {
          coordinates.push(feature.coordinates);
        } else if (type === 'LineString') {
          coordinates = feature.coordinates;
        } else if (type === 'Polygon') {
          coordinates = feature.coordinates[0];
        }

        for (let point of coordinates) {
          bounds.extend({ lat: point[1], lng: point[0] });
        }
      }

      return bounds;
    }

    function getCenterFromCoordinates(coordinates, type) {
      let center = null;
      if (type === 'Point') {
        center = {
          lat: coordinates[1],
          lng: coordinates[0],
        };
      } else if (['Polygon', 'LineString'].indexOf(type) !== -1) {
        if (type === 'Polygon') {
          coordinates = coordinates[0];
        }
        let sums = {
          lats: 0,
          lngs: 0,
        };
        let length = coordinates.length;
        coordinates.forEach((coordinate) => {
          sums.lats += Number.parseFloat(coordinate[1]);
          sums.lngs += Number.parseFloat(coordinate[0]);
        });

        if (length > 0) {
          center = {
            lat: sums.lats / length,
            lng: sums.lngs / length,
          };
        }
      }
      return center;
    }

    function toGeoJSON(figure, type) {
      type = type.charAt(0).toUpperCase() + type.slice(1);

      let style = JSON.parse(JSON.stringify(figure.style));
      if (figure.asset) {
        delete style.icon;
      } else if (style.icon) {
        delete style.icon.url;
        delete style.icon.anchor;
      }
      delete style.draggable;

      let feature = {
        type: 'Feature',
        geometry: {
          type: type,
          coordinates: [],
        },
        properties: {
          style: style,
          info: figure.info,
          assetVisible: figure.assetVisible !== null,
          sensor: figure.sensor,
          isCamera: figure.isCamera,
          minZoom: figure.minZoom,
          maxZoom: figure.maxZoom,
        },
        id: figure.id,
        assetId: figure.asset ? figure.asset.id : undefined,
      };

      feature.properties.sensors = [];
      if (figure.sensors) {
        for (let prop in figure.sensors) {
          if (prop !== figure.sensor) {
            feature.properties.sensors.push(prop);
          }
        }
      }

      if (feature.properties.sensors.length === 0) {
        delete feature.properties.sensors;
      }

      let array, i, j, vertices, xy, paths;
      let ring, first, last;

      switch (type) {
        case 'Point':
          let pos = figure.getPosition();
          feature.geometry.coordinates.push(pos.lng());
          feature.geometry.coordinates.push(pos.lat());
          break;
        case 'Polygon':
          array = [];
          paths = figure.getPaths();
          for (i = 0; i < paths.getLength(); i++) {
            vertices = paths.getAt(i);
            ring = [];
            for (j = 0; j < vertices.getLength(); j++) {
              xy = vertices.getAt(j);
              ring.push([xy.lng(), xy.lat()]);
            }
            first = ring[0];
            last = ring[ring.length - 1];
            if (first[0] !== last[0] && first[1] !== last[1]) {
              ring.push(first);
            }
            array.push(ring);
          }
          feature.geometry.coordinates = array;

          if (figure.label && figure.label.getPosition()) {
            let position = figure.label.getPosition();
            feature.properties.label = {
              position: {
                lat: position.lat(),
                lng: position.lng(),
              },
            };
          }
          break;
        case 'MultiPolygon':
          array = [];
          paths = figure.getPaths();
          for (i = 0; i < paths.getLength(); i++) {
            vertices = paths.getAt(i);
            ring = [];
            for (j = 0; j < vertices.getLength(); j++) {
              xy = vertices.getAt(j);
              ring.push([xy.lng(), xy.lat()]);
            }
            first = ring[0];
            last = ring[ring.length - 1];
            if (first[0] !== last[0] && first[1] !== last[1]) {
              ring.push(first);
            }
            array.push(ring);
          }

          feature.geometry.coordinates.push(array);

          if (figure.label && figure.label.getPosition()) {
            let position = figure.label.getPosition();
            feature.properties.label = {
              position: {
                lat: position.lat(),
                lng: position.lng(),
              },
            };
          }
          break;
        case 'LineString':
          array = [];
          vertices = figure.getPath();
          for (i = 0; i < vertices.getLength(); i++) {
            xy = vertices.getAt(i);
            array.push([xy.lng(), xy.lat()]);
          }

          feature.geometry.coordinates = array;

          if (figure.label) {
            let position = figure.label.getPosition();
            feature.properties.label = {
              position: {
                lat: position.lat(),
                lng: position.lng(),
              },
            };
          }

          if (figure.traceName) {
            let position = figure.traceNameMarker
              ? figure.traceNameMarker.getPosition()
              : undefined;
            feature.properties.traceName = {
              text: figure.traceName.text,
              show: figure.traceName.show,
              position: position ? position.toJSON() : undefined,
            };
          }

          if (figure.totalLength) {
            let position = figure.totalLengthMarker
              ? figure.totalLengthMarker.getPosition()
              : undefined;
            feature.properties.totalLength = {
              length: figure.totalLength.length,
              show: figure.totalLength.show,
              position: position ? position.toJSON() : undefined,
            };
          }

          feature.properties.showStart = figure.showStart || undefined;
          feature.properties.showSections = figure.showSections || undefined;

          break;
        default:
          console.log(type + ' Not supported');
          return null;
      }

      return feature;
    }

    function loadGeoJSON(data, map, featureType, render) {
      let result = { markers: [], polygons: [], lines: [] };
      if (featureType && typeof featureType === 'string') {
        featureType = [featureType];
      }

      if (data.type !== 'FeatureCollection') {
        data = { type: 'FeatureCollection', features: [data] };
      }

      let features = data.features;
      for (let i = 0; i < features.length; i++) {
        let current = features[i];
        let label;

        if (!current.type) {
          continue;
        }

        if (current.type !== 'Feature') {
          current = {
            type: 'Feature',
            geometry: current,
          };
        }

        if (featureType && featureType.indexOf(current.geometry.type) === -1) {
          continue;
        }

        if (!current.properties) {
          current.properties = { style: {} };
        }

        if (!current.properties.style) {
          current.properties.style = {};
        }

        current.properties.asset = current.assetId;

        let geometry = current.geometry;
        let type = geometry.type;
        let coors = geometry.coordinates;
        let lowerType = type.charAt(0).toLowerCase() + type.slice(1);
        let style;

        switch (type) {
          case 'Point':
            let currentStyle = current.properties.style;
            if (currentStyle.icon) {
              currentStyle.icon = Object.assign(
                currentStyle.icon,
                gisUtils.getMarker(currentStyle.icon.options)
              );
            }

            style = $.extend(true, {}, gisUtils.defaults.point, currentStyle);
            let mprops = $.extend(
              {
                lat: coors[1],
                lng: coors[0],
              },
              style
            );
            let marker = gisUtils.addMarker(mprops, map, render);
            let position = marker.getPosition();
            marker.properties = current.properties;
            marker.geoType = lowerType;
            marker.style = style;
            marker.info = current.properties.info;
            marker.id = current.id || utils.generateUUID();
            marker.lat = position.lat();
            marker.lng = position.lng();
            result.markers.push(marker);
            break;
          case 'Polygon':
          case 'MultiPolygon':
            style = $.extend({}, gisUtils.defaults.lines, current.properties.style);

            label = current.properties.label || {};
            label.position = label.position || { lat: coors[0][0][1], lng: coors[0][0][0] };

            if (Array.isArray(label.position.lng)) {
              label.position = { lat: label.position.lng[1], lng: label.position.lng[0] };
            }

            let polygon = gisUtils.addPolygon(true, coors, style, map, render);
            polygon.properties = current.properties;
            polygon.geoType = lowerType;
            polygon.style = style;
            polygon.info = current.properties.info;
            polygon.id = current.id || utils.generateUUID();

            result.polygons.push(polygon);

            // label.map = map.map
            label.raiseOnDrag = false;
            label.icon = window.assetsPath + '/images/markers/label-marker.png';

            polygon.label = new GMAP.Marker(label);
            break;
          case 'LineString':
            style = $.extend({}, gisUtils.defaults.lines, current.properties.style);
            let line = gisUtils.addLine(true, coors, style, map, render);
            line.properties = current.properties;
            line.geoType = lowerType;
            line.style = style;
            line.info = current.properties.info;
            line.id = current.id || utils.generateUUID();

            result.lines.push(line);

            label = current.properties.label || {};
            label.position = label.position || { lat: coors[0][1], lng: coors[0][0] };
            // label.map = map.map
            label.raiseOnDrag = false;
            label.icon = window.assetsPath + '/images/markers/label-marker.png';

            line.label = new GMAP.Marker(label);
            break;
          default:
            console.log(type + ' Not supported');
        }
      }

      return result;
    }

    function layerToGeoJSON(layer) {
      let features = layer.features;
      let jsonLayer = {
        id: layer.id,
        name: layer.name,
        visible: layer.visible,
        features: [],
      };

      for (let prop in features) {
        let figure = features[prop];
        let feature = _this.toGeoJSON(figure, figure.geoType);

        if (feature) {
          jsonLayer.features.push(feature);
        }
      }

      return jsonLayer;
    }
  }
})();
