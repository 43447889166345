(function () {
  'use strict';
  const angular = window.angular;

  StaticService.$inject = [
    '$q',
    '$http',
    'AppUtils',
    'TimeZone',
    'Country',
    'YoloClass',
    '$translate',
  ];

  angular.module('commonServices').service('StaticService', StaticService);

  function StaticService($q, $http, utils, TimeZone, Country, YoloClass, $translate) {
    let service = {};
    return {
      Country: {
        find: getCountries,
        getGeoJSON: getGeoJSON,
      },
      TimeZone: {
        find: getTimezones,
      },
      YoloClasses: {
        find: getYoloClasses,
      },
    };

    function getCountries() {
      let defer = $q.defer();

      if (service.countries) {
        defer.resolve(service.countries);
      } else {
        Country.find()
          .$promise.then((countries) => {
            service.countries = countries;
            defer.resolve(countries);
          })
          .catch((err) => {
            err = utils.getHTTPError(err);
            defer.reject(err);
          });
      }

      return defer.promise;
    }

    function getTimezones() {
      let defer = $q.defer();

      if (service.timeZones) {
        defer.resolve(service.timeZones);
      } else {
        TimeZone.find()
          .$promise.then((timeZones) => {
            service.timeZones = timeZones;
            defer.resolve(timeZones);
          })
          .catch((err) => {
            err = utils.getHTTPError(err);
            defer.reject(err);
          });
      }

      return defer.promise;
    }

    function getYoloClasses() {
      if (service.yoloClasses) {
        return utils.Promise.resolve(service.yoloClasses);
      }

      return YoloClass.find({
        filter: { fields: ['id', 'name'] },
      })
        .$promise.then((yoloClasses) => {
          for (let yoloClass of yoloClasses) {
            yoloClass.value = yoloClass.name.replace(/\s/g, '');
            yoloClass.label = $translate.instant('entities.sensor.__classes.' + yoloClass.value);
          }

          yoloClasses.sort((a, b) => {
            if (a.value === 'person') {
              return -1;
            }
            if (b.value === 'person') {
              return 1;
            }
            return a.label > b.label ? 1 : -1;
          });
          service.yoloClasses = yoloClasses;
          return yoloClasses;
        })
        .catch((err) => {
          err = utils.getHTTPError(err);
          throw err;
        });
    }

    function getGeoJSON(country) {
      const countries = {
        chile: {
          url: 'https://rawcdn.githack.com/jlhonora/geo/461431de2ad951184f54db5a5b7ba2d65275d5b5/low_res/all.geojson',
          cleaner: cleanChileGeoJSON,
        },
      };

      if (!country || !countries[country.toLowerCase()]) {
        return Promise.reject(new Error('Invalid Country'));
      }

      const options = countries[country.toLowerCase()];
      return $http
        .get(options.url)
        .then((response) => {
          let data = response.data;
          if (options.cleaner) {
            data = cleanChileGeoJSON(data);
          }
          return data;
        })
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function cleanChileGeoJSON(geojson) {
      const regions = [];
      geojson.features = geojson.features.filter((current) => {
        if (!current.geometry) {
          return;
        }
        current.properties = {
          region: current.properties.NOM_REG,
        };
        regions.push(current.properties.region);
        return current;
      });

      return geojson;
    }
  }
})();
