(function () {
  'use strict';
  const angular = window.angular;

  AssetStateService.$inject = [];

  angular.module('commonServices').service('AssetStateService', AssetStateService);

  function AssetStateService() {
    return {
      getCronExpression
    };

    function getCronExpression(scheduler) {
      return scheduler
        ? `${scheduler.minute} ${scheduler.hour} ${scheduler.dom} ${scheduler.mon} ${scheduler.dow}`
        : null;
    }
  }
})();
