(() => {
  angular.module('commonServices').service('GisGeocodingService', GisGeocodingService);

  GisGeocodingService.$inject = ['$http', 'LoopBackAuth'];

  function GisGeocodingService($http, LoopBackAuth) {
    this.geocode = geocode;

    function geocode(address) {
      const accessToken = LoopBackAuth.accessTokenId;
      const url = `${window.apiPath}/geocode?access_token=${accessToken}&address=${address}`;

      return $http.get(url).then((response) => {
        return response.data;
      });
    }
  }
})();
