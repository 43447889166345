(() => {
  let angular = window.angular;

  RouterService.$inject = [
    'Customer',
    'LocalStorageService',
    'UserService',
    'AppUtils',
    'ProjectService',
    'StaticService',
    '$translate',
    'Var',
  ];

  angular.module('commonServices').service('RouterService', RouterService);

  function RouterService(
    Customer,
    LocalStorageService,
    UserService,
    utils,
    ProjectService,
    StaticService,
    $translate,
    Var
  ) {
    return {
      storeId: storeId,
      getPrincipal: getPrincipal,
      getVarCustomers: getVarCustomers,
      getProject: getProject,
      getProjects: getProjects,
      getProjectAssetTypes: getProjectAssetTypes,
      getCountries: getCountries,
      getTimezones: getTimezones,
      getYoloClasses: getYoloClasses,
      getCustomer: getCustomer,
      getManagersCount: getManagersCount,
      getManagers: getManagers,
    };

    // Verifica si el id esta guardado en memoria
    // Si id leido != id actual
    //  Cambio el usuario -> borrar todo el cache
    //  Cambio proyecto -> borrar cache proyecto
    //  Cambio cliente -> borrar cache cliente
    // O si no esta guardado en cache, entonces
    // Almacena nuevo id

    function storeId(id, context) {
      const key = 'ID$' + context;

      let _readId = LocalStorageService.getItem(key);
      if (_readId !== id) {
        localStorage.removeItem(key);
        LocalStorageService.clear(context);
      }

      LocalStorageService.setItem(key, id);
    }

    function getPrincipal(_user, include) {
      include = include || ['container'];
      let obj = {
        func: UserService.findById,
        args: [_user.id, { include: include }],
      };
      return loadKey('Principal', obj);
    }

    function getVarCustomers(varId) {
      let obj = {
        func: Var.customers,
        args: [
          {
            id: varId,
            filter: {
              order: 'name ASC',
            },
          },
        ],
      };
      return loadKey('Var$Customers', obj);
    }

    function getProject(_params) {
      let obj = {
        func: ProjectService.findById,
        args: [
          _params.customerId,
          _params.projectId,
          {
            include: [
              { relation: 'tools', scope: { fields: ['id', 'module', 'name'] } },
              { relation: 'adminTools', scope: { fields: ['id', 'module', 'name'] } },
              { relation: 'tags', scope: { fields: ['id', 'name', 'icon'] } },
              {
                relation: 'allowedSensorTypes',
                scope: { fields: ['id', 'name', 'type', 'allowFiles', 'allowVirtualExpressions'] },
              },
              { relation: 'yoloClasses', scope: { fields: ['id', 'name'] } },
            ],
            order: 'name ASC',
          },
        ],
      };
      return loadKey('Customer$CurrentProject', obj).then((project) => {
        for (let yoloClass of project.yoloClasses) {
          yoloClass.value = yoloClass.name.replace(/\s/g, '');
          yoloClass.label = $translate.instant('entities.sensor.__classes.' + yoloClass.value);
        }
        return project;
      });
    }

    function getProjectAssetTypes(_params) {
      let obj = {
        func: Customer.prototype$__get__projects__assetTypes,
        args: [
          {
            id: _params.customerId,
            fk: _params.projectId,
            filter: {
              include: ['sensorTypes', 'assetWizardTypes'],
            },
          },
        ],
      };
      return loadKey('Customer$CurrentProject$AssetTypes', obj);
    }

    function getCountries() {
      let obj = {
        func: StaticService.Country.find,
        args: [],
      };
      return loadKey('Countries', obj);
    }

    function getTimezones() {
      let obj = {
        func: StaticService.TimeZone.find,
        args: [],
      };
      return loadKey('Timezones', obj);
    }

    function getYoloClasses() {
      let obj = {
        func: StaticService.YoloClasses.find,
        args: [],
      };
      return loadKey('YoloClasses', obj);
    }

    function getProjects(_params) {
      let obj = {
        func: ProjectService.findProjects,
        args: [_params.customerId, { name: true, id: true }],
      };
      return loadKey('Customer$Projects', obj);
    }

    function getCustomer(_params) {
      let obj = {
        func: Customer.findById,
        args: [
          {
            id: _params.customerId,
            filter: { include: ['container'] },
          },
        ],
      };
      // retorna customer
      return loadKey('Customer', obj);
    }

    function getManagersCount(_params) {
      let obj = {
        func: Customer.prototype$__count__managers,
        args: [
          {
            id: _params.customerId,
          },
        ],
      };
      // retorna managers.count
      return loadKey('Customer$Manager$Count', obj).then((managers) => {
        return managers.count;
      });
    }

    function getManagers(_params) {
      let obj = {
        func: Customer.managers,
        args: [
          {
            id: _params.customerId,
            filter: { include: ['container'] },
          },
        ],
      };
      // retorna managers
      return loadKey('Customer$Manager$List', obj);
    }

    function loadKey(key, { func, args }) {
      let item = LocalStorageService.getItem(key);
      if (item) {
        return utils.Promise.resolve(item);
      }

      let promise = func.apply(null, args);
      promise = promise.$promise ? promise.$promise : promise;
      return promise.then((result) => {
        LocalStorageService.setItem(key, result);
        return result;
      });
    }
  }
})();
