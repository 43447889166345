(() => {
  const angular = window.angular;

  Service.$inject = ['AppUtils', 'ObjectRecognitionService', '$translate'];

  angular
    .module('commonServices')
    .service('SensorTableData', Service);

  function Service (utils, ObjectRecognitionService, $translate) {
    const classes = utils.arrayToObject(ObjectRecognitionService.getClasses(), 'value');
    const poses = utils.arrayToObject(ObjectRecognitionService.getPoses(), 'value');

    return {
      getData
    };

    function getData (sensor, data) {
      const method = {
        CrossLineMultiRecognition: CrossLineMultiRecognition,
        FaceDetection,
        FaceMaskDetection: FaceDetection,
        NaiveSocialDistancing: ObjectRecognition,
        Number,
        ObjectCounting,
        ObjectRecognition,
        PoseDetection,
        SpeedChange: ObjectRecognition,
        StoppedLicensePlate: StoppedLicensePlate,
        StoppedObjectsDetector: ObjectRecognition,
        String: Number
      };

      if (!method[sensor.type]) {
        return toJSON(sensor, data);
      }

      return method[sensor.type](sensor, data);
    }

    function CrossLineMultiRecognition (sensor, data) {
      const result = [];
      data.forEach(current => {
        const row = { from: current.from, sensor: sensor, value: [] };
        for (let _class in current.content) {
          const content = current.content[_class];
          const label = classes[_class].label;
          row.value.push(`${label}: ${JSON.stringify(content)}`);
        }

        row.value = row.value.join(', ');
        result.push(row);
      });
      return result;
    }

    function toJSON (sensor, data) {
      const result = [];
      data.forEach(current => {
        result.push({
          from: current.from.getTime(),
          value: JSON.stringify(current.content),
          sensor: sensor
        });
      });

      return result;
    }

    function Number (sensor, data) {
      const result = [];
      data.forEach(current => {
        result.push({
          from: current.from.getTime(),
          value: current.content,
          sensor: sensor
        });
      });

      return result;
    }

    function ObjectCounting (sensor, data) {
      const result = [];
      data.forEach(current => {
        const row = { from: current.from, sensor: sensor, value: [] };
        for (let _class in current.content) {
          const count = current.content[_class];
          const label = classes[_class].label;
          row.value.push(`${label}: ${count.length}`);
        }

        row.value = row.value.join(', ');
        result.push(row);
      });
      return result;
    }

    function ObjectRecognition (sensor, data) {
      const result = [];
      data.forEach(current => {
        const row = { from: current.from, sensor: sensor, value: [] };
        const content = current.content;
        const detections = {};
        for (let detection of content.objects) {
          const _class = detection.class;
          if (!classes[_class]) {
            continue;
          }
          detections[_class] = detections[_class] ? detections[_class] + 1 : 1;
        }

        for (const _class in detections) {
          const count = detections[_class];
          const label = classes[_class].label;
          row.value.push(`${label}: ${count}`);
        }

        row.value = row.value.join(', ');
        result.push(row);
      });

      return result;
    }

    function PoseDetection (sensor, data) {
      const result = [];
      data.forEach(current => {
        const row = { from: current.from, sensor: sensor, value: [] };
        const content = current.content;
        const detections = {};

        for (let detection of content.poses) {
          const label = detection.label.toLowerCase();
          if (!poses[label]) {
            continue;
          }
          detections[label] = detections[label] ? detections[label] + 1 : 1;
        }

        for (let key in detections) {
          const count = detections[key];
          const label = poses[key].label;
          row.value.push(`${label}: ${count}`);
        }

        row.value = row.value.join(', ');
        result.push(row);
      });

      return result;
    }

    function FaceDetection (sensor, data) {
      const result = [];
      const faceLabel = $translate.instant('entities.sensor.__classes.face');
      const maskLabel = $translate.instant('entities.sensor.__classes.mask');

      data.forEach(current => {
        const row = { from: current.from, sensor: sensor, value: [] };
        const faces = current.content.faces || [];
        row.value.push(`${faceLabel}: ${faces.length}`);

        if (sensor.type === 'FaceMaskDetection') {
          const maskCount = faces.reduce((sum, current) => current.mask && current.mask.probability ? sum + 1 : sum, 0);
          row.value.push(`${maskLabel}: ${maskCount}`);
        }

        row.value = row.value.join(', ');
        result.push(row);
      });

      return result;
    }

    function StoppedLicensePlate (sensor, data) {
      const result = [];
      data.forEach(current => {
        const row = { from: current.from, sensor: sensor, value: [] };
        current.content.detections.forEach(detection => {
          row.value.push(detection.content + (detection.stopped ? ' [Stopped]' : ''));
        });

        row.value.join(', ');
        result.push(row);
      });

      return result;
    }
  }

})();
