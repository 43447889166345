(() => {
  angular.module('commonServices').service('LogUtils', [Service]);

  function Service() {
    const logRegex =
      /\[([0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z)[ ]+([A-Za-z]+)[ ]+(.+?)][ ]+(.+)/;
    return {
      parse,
    };

    function parse(log) {
      log = scapeHTML(log);
      const match = logRegex.exec(log);
      return {
        date: match && match[0] ? new Date(match[1]).toISOString() : '',
        status: match && match[0] ? (match[2] || '').toUpperCase() : '',
        service: match && match[0] ? match[3] : '',
        content: match && match[0] ? match[4] : log,
      };
    }

    function scapeHTML(log) {
      var entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
      };

      return String(log).replace(/[&<>"'\/]/g, function (s) {
        return entityMap[s];
      });
    }
  }
})();
