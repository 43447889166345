;(() => {
  const angular = window.angular;

  VirtualGroupService.$inject = ['Customer', 'VirtualExpressionService', 'AppUtils'];

  angular
    .module('commonServices')
    .service('VirtualGroupService', VirtualGroupService);

  function VirtualGroupService (Customer, VirtualExpressionService, utils) {
    return {
      find: find,
      findById: findById,
      getTree: getTree
    };

    function find (customerId, projectId, filter) {
      return Customer
        .prototype$__get__projects__virtualGroups({
          id: customerId,
          nk: projectId,
          filter: filter
        })
        .$promise
        .then(function (groups) {
          groups.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
          groups.forEach(current => {
            const variables = current.virtualVariables = current.virtualVariables || [];
            current.virtualExpressions = current.virtualExpressions || [];
            current.virtualConstants = [];

            current.virtualConstants = current.virtualVariables.filter(variable => !variable.type);
            current.virtualVariables = current.virtualVariables.filter(variable => variable.type);

            current.virtualExpressions.forEach(expression => {
              expression.readableExpression = VirtualExpressionService.getReadableExpression(expression, variables);
            });
          });
          return groups;
        })
        .catch(err => {
          throw utils.getHTTPError(err);
        });
    }

    function findById (customerId, projectId, virtualGroupId, filter) {
      filter = typeof filter === 'object' ? filter : {};
      filter.where = filter.where || {};
      filter.where.id = virtualGroupId;

      return find(customerId, projectId, filter)
        .then(result => {
          if (!result.length) {
            const err = new Error('Not Found');
            err.code = 'NOT_FOUND';
            err.statusCode = 404;
            throw err;
          }
          return result[0];
        })
        .catch(err => {
          throw utils.getHTTPError(err);
        });
    }

    function getTree (virtualGroups) {
      let tree = [];
      virtualGroups.forEach(group => {
        tree.push({ id: group.id, text: group.name, parent: '#' });
      });
      return tree;
    }
  }
})();
