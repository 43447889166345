(function () {
  const $ = window.$;
  const GMAP = window.google ? window.google.maps : null;
  const navigator = window.navigator;

  gisUtils.$inject = [
    'AppUtils',
    '$timeout',
    'SensorService',
    'GisThemesService',
    '$filter',
    'iconService',
    'MarkerService',
    '$rootScope',
  ];

  angular.module('commonServices').service('GisUtils', gisUtils);

  function gisUtils(
    utils,
    $timeout,
    sensorService,
    themes,
    $filter,
    iconService,
    MarkerService,
    $root
  ) {
    let _this = this;
    let icon = getMarker();

    this.defaults = {
      point: {
        icon: GMAP
          ? Object.assign(
              {},
              {
                anchor: new GMAP.Point(13, 38),
                scaledSize: new GMAP.Size(26, 38),
                color: '#000000',
              },
              icon
            )
          : null,
        draggable: true,
      },
      lines: {
        strokeColor: '#3d85c6',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: '#6fa8dc',
        fillOpacity: 0.4,
      },
    };

    this.getCurrentLocation = getCurrentLocation;

    this.createMap = createMap;

    this.addMapEvent = addMapEvent;
    this.addFigureEvent = addFigureEvent;
    this.setFigureState = setFigureState;

    this.addMarker = addMarker;
    this.addPolygon = addPolygon;
    this.addLine = addLine;

    this.centerOn = centerOn;

    this.getMarker = getMarker;

    this.setMarkerColor = setMarkerColor;
    this.setStyleProperty = setStyleProperty;
    this.validateStyle = validateStyle;

    this.toggleInfo = toggleInfo;
    this.getInfoWindowContent = getInfoWindowContent;

    this.removeFigure = removeFigure;

    this.select = select;
    this.deselect = deselect;

    this.setZoom = setZoom;
    this.zoomIn = zoomIn;
    this.zoomOut = zoomOut;

    this.createInfoWindow = createInfoWindow;

    this.addFigureLabelClass = addFigureLabelClass;
    this.removeFigureLabelClass = removeFigureLabelClass;

    function getCurrentLocation(cb) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            $timeout(() => {
              const coords = position ? position.coords : { latitude: 0, longitude: 0 };
              cb({ lat: coords.latitude, lng: coords.longitude });
            });
          },
          (err) => {
            console.log(err);
            $timeout(() => {
              cb({ lat: 0, lng: 0, zoom: 2 });
            });
          },
          {
            maximumAge: 60 * 60 * 1000,
            timeout: 8000,
            enableHighAccuracy: false,
          }
        );
      } else {
        cb({ lat: 0, lng: 0, zoom: 2 });
      }
    }

    function createMap(props, readOnly) {
      if (props.mapTypeId && typeof props.mapTypeId === 'string') {
        props.mapTypeId = GMAP.MapTypeId[props.mapTypeId];
      }

      if (!props.styles) {
        props.styles = $root.darkMode ? themes.CLEAR_NIGHT() : themes.CLEAR_STANDARD();
      }

      let map = new GMaps(props);

      _this.readOnly = readOnly || false;
      _this.defaults.point.draggable = !this.readOnly;

      if (!this.readOnly) {
        let overlay = new GMAP.OverlayView();
        overlay.draw = function () {};
        overlay.setMap(map.map);

        map.helperOverlay = overlay;
      }

      return map;
    }

    function addMarker(props, map, render) {
      render = typeof render === 'boolean' ? render : true;
      if (_this.readOnly) {
        props.draggable = false;
      }

      props.position = {
        lat: props.lat,
        lng: props.lng,
      };

      props.labelClass = 'label label-marker';
      props.labelVisible = false;

      if (props.icon && props.icon.options) {
        props.icon.options.color = props.icon.options.markerColor;
      } else {
        props.icon = props.icon || {};
        if (typeof props.icon === 'object') {
          props.icon.options = props.icon.options || {};
        }
      }

      props.icon = Object.assign({}, _this.defaults.point.icon, props.icon, props.icon.options);
      props.map = map.map;
      const marker = new window.MarkerWithLabel(props);

      if (!render) {
        marker.setMap(null);
      }

      marker.style = {
        icon: props.icon,
      };
      return marker;
    }

    function addPolygon(geoJSON, paths, style, map, render) {
      render = typeof render === 'boolean' ? render : true;
      let obj = { useGeoJSON: geoJSON, paths: paths };

      $.extend(obj, style);

      const polygon = map.drawPolygon(obj);

      if (!render) {
        polygon.setMap(null);
      }

      return polygon;
    }

    function addLine(geoJSON, path, style, map, render) {
      render = typeof render === 'boolean' ? render : true;
      if (geoJSON) {
        for (let i = 0; i < path.length; i++) {
          let current = path[i];

          if (current.length > 2) {
            current.pop();
          }
          path[i] = current.reverse();
        }
      }

      let obj = { path: path };
      $.extend(obj, style);

      const line = map.drawPolyline(obj);
      if (!render) {
        line.setMap(null);
      }

      return line;
    }

    function addFigureEvent(figure, event, callback) {
      GMAP.event.addListener(figure, event, function (e) {
        $timeout(() => {
          callback(figure, e);
        });
      });
    }

    function addMapEvent(map, event, callback) {
      GMAP.event.addListener(map.map, event, function (e) {
        $timeout(() => {
          callback(e);
        });
      });
    }

    function setFigureState(figure, color) {
      let type = figure.geoType;
      color = color || '#ffffff';

      switch (type) {
        case 'point':
          setMarkerIconUrl(figure, getMarker({ statusColor: color }));
          break;
        case 'polygon':
          break;
        case 'multiPolygon':
          break;
        case 'lineString':
          break;
      }
    }

    function setMarkerIconUrl(figure, url) {
      let icon = figure.getIcon();

      let iconUrl = typeof url === 'object' ? url.url : url;
      let options = typeof url === 'object' ? url.options : undefined;

      if (typeof icon === 'object') {
        icon.url = iconUrl;
        icon.options = options;
      } else {
        icon = iconUrl;
      }

      figure.setIcon(icon);
    }

    function addFigureLabelClass(figure, cssClass) {
      if (
        !figure ||
        !cssClass ||
        ['point', 'polygon', 'linestring'].indexOf(figure.geoType) === -1
      ) {
        return;
      }

      cssClass = cssClass.trim().replace(/\s\s+/g, ' ');
      if (figure.geoType === 'point') {
        let classes = (figure.get('labelClass') || '').split(' ');

        if (classes.indexOf(cssClass) === -1) {
          classes.push(cssClass);
          figure.set('labelClass', classes.join(' '));
        }
      }
    }

    function removeFigureLabelClass(figure, cssClass) {
      if (
        !figure ||
        !cssClass ||
        ['point', 'polygon', 'linestring'].indexOf(figure.geoType) === -1
      ) {
        return;
      }

      cssClass = cssClass.trim().replace(/\s\s+/g, ' ');
      if (figure.geoType === 'point') {
        let classes = (figure.get('labelClass') || '').split(' ');
        const index = classes.indexOf(cssClass);
        if (index !== -1) {
          classes.splice(index, 1);
          figure.set('labelClass', classes.join(' '));
        }
      }
    }

    function getMarkerIconUrl(figure) {
      let icon = figure.getIcon();
      return typeof icon === 'object' ? icon.url : icon;
    }

    function centerOn(figure, map, zoom) {
      let type = figure.geoType;
      let bounds;
      switch (type) {
        case 'point':
          let pos = figure.getPosition();

          map.map.setCenter(pos);
          map.setZoom(zoom ? zoom : 12);
          break;
        case 'polygon':
        case 'multiPolygon':
          bounds = new GMAP.LatLngBounds();
          figure.getPaths().forEach((path) => {
            path.forEach((pair) => {
              bounds.extend(pair);
            });
          });

          map.map.fitBounds(bounds);
          break;
        case 'lineString':
          bounds = new GMAP.LatLngBounds();
          figure.getPath().forEach((pair) => {
            bounds.extend(pair);
          });

          map.map.fitBounds(bounds);
          break;
        default:
          throw new Error(type + ' Not supported');
      }
    }

    function select(figure) {
      let type = figure.geo ? figure.geo.type : null;

      switch (type) {
        case 'point':
          break;
        case 'polygon':
          break;
        case 'multiPolygon':
          break;
        case 'lineString':
          break;
      }
    }

    function deselect(figure) {
      let type = figure.geo ? figure.geo.type : null;

      switch (type) {
        case 'point':
          let url = getMarkerIconUrl(figure);
          url = url.replace('/selected', '');

          setMarkerIconUrl(figure, url);
          break;
        case 'polygon':
          break;
        case 'multiPolygon':
          break;
        case 'lineString':
          break;
      }
    }

    function getMarker(options) {
      let config = {
        textColor: '#000000',
        markerColor: '#000000',
        markerSize: 'normal',
        statusColor: '#ffffff',
        statusSize: 'lg',
        type: 'default',
      };

      options = Object.assign({}, config, options);

      return {
        url:
          'data:image/svg+xml;charset=utf-8,' +
          encodeURIComponent(
            MarkerService.getMarker(options.type, {
              markerColor: options.markerColor,
              figureColor: options.statusColor,
              rotation: options.rotation ? options.rotation : '',
            })
          ),
        options: options,
      };
    }

    function removeFigure(vm, figure) {
      figure.setMap(null);

      let layerId = figure.layer;
      let figureId = figure.id;

      if (figure.asset) {
        delete vm.assetFigures[figure.asset.id];
      }

      delete vm.layers[layerId].features[figureId];

      if (figure === vm.figure) {
        vm.setFigureSelected(null);
      }
    }

    function setMarkerColor(figure, statusColor, selected) {
      let color = '#000000';
      if (typeof statusColor === 'object') {
        color = statusColor.color || '#000000';
        statusColor = statusColor.statusColor || '#ffffff';
      }

      let options = angular.copy(figure.getIcon().options);
      options.markerColor = color;
      options.statusColor = statusColor;

      figure.style.icon.color = color;
      setMarkerIconUrl(figure, getMarker(options));
    }

    function setStyleProperty(figure, property) {
      if (!figure) {
        return;
      }

      let style = {};
      style[property] = figure.style[property] || 0;

      figure.setOptions(style);
    }

    function validateStyle(figure, property) {
      if (!figure) {
        return;
      }

      if (!figure.style[property]) {
        figure.style[property] = 0;
      }
    }

    function toggleInfo(figure, labelClass) {
      if (!figure || ['point', 'polygon', 'linestring'].indexOf(figure.geoType) === -1) {
        return;
      }

      labelClass = labelClass || 'label-marker-lg';

      if (figure.asset) {
        if (figure.geoType === 'point') {
          if (figure.assetVisible) {
            let width = utils.getTextWidth(figure.asset.name, 'label label-marker ' + labelClass);
            width = width > 150 ? 150 : width;

            figure.set('labelClass', 'label label-marker ' + labelClass);
            figure.set('labelContent', figure.asset.name);
            figure.set('labelAnchor', new GMAP.Point(Math.floor(width / 2), 0));
            figure.set('labelVisible', true);
          } else {
            figure.set('labelContent', '');
            figure.set('labelVisible', false);
          }
        } else if (figure.geoType === 'polygon') {
          if (!figure.label) {
            return;
          }
          figure.label.setMap(figure.assetVisible ? figure.map : null);
        }
      } else if (figure.element) {
        if (figure.geoType === 'point') {
          let width = utils.getTextWidth(figure.element.name, 'label label-marker ' + labelClass);
          width = width > 150 ? 150 : width;

          figure.set('labelClass', 'label label-marker ' + labelClass);
          figure.set('labelContent', figure.element.name);
          figure.set('labelAnchor', new GMAP.Point(Math.floor(width / 2), 0));
          figure.set('labelVisible', true);
        } else if (figure.geoType === 'polygon') {
          if (!figure.label) {
            return;
          }
          figure.label.setMap(figure.map);
        }
      } else {
        figure.assetVisible = false;
        figure.set('labelContent', '');
        figure.set('labelVisible', false);

        if (figure.label) {
          figure.label.setMap(null);
        }
      }
    }

    function getInfoWindowContent(figure, sensors, getInfoSensorRow) {
      if (!figure || !figure.asset) {
        return;
      }

      getInfoSensorRow = getInfoSensorRow || defaultInfoSensorRow;
      const iconPath = iconService.getAssetIcon(figure.asset, 'white');
      let content = `<div class="gmaps-info">
        <div class="info-window-header">
        <span class="header-icon"><img src="${iconPath}" alt="${figure.asset.icon}"/></span> ${figure.asset.name}
      </div><div class="info-window-body sensors">`;

      if (figure.sensor || figure.sensors) {
        if (figure.sensor) {
          let sensor = sensors[figure.sensor];
          let value = figure.value;
          let timestamp = figure.timestamp;
          let color = figure.color;

          content += getInfoSensorRow(sensor, value, timestamp, color, true);
        }

        if (figure.sensors) {
          for (let prop in figure.sensors) {
            let current = figure.sensors[prop];
            if (!current.sensor) {
              continue;
            }

            let sensor = sensors[current.sensor];
            let value = current.value;
            let timestamp = current.timestamp;
            let color = current.color;

            if (!sensor) {
              continue;
            }

            content += getInfoSensorRow(sensor, value, timestamp, color);
          }
        }
      }

      content += '<div class="clearfix"></div></div>';
      content += '</div>';
      let aux = $(content).hide().appendTo('body');

      let height = aux.height();
      let width = aux.width();

      // content = `<div style="height: ${height}px; width: ${width}px">${content}</div>`;
      aux.remove();

      return content;
    }

    function zoomIn(map) {
      let current = map.getZoom();

      if (current === 21) {
        return;
      }

      map.setZoom(current + 1);
    }

    function zoomOut(map) {
      let current = map.getZoom();
      if (current === 0) {
        return;
      }

      map.setZoom(current - 1);
    }

    function setZoom(map) {
      map.setZoom(map.zoom);
    }

    function createInfoWindow(options) {
      const infoWindow = new GMAP.InfoWindow(
        Object.assign(
          {
            pixelOffset: new GMAP.Size(0, -40),
            maxWidth: 276,
          },
          options
        )
      );

      GMAP.event.addListener(infoWindow, 'domready', function () {
        let content = $('.gm-style-iw');
        content.addClass('gm-style-iw-custom').prev().hide();
      });

      GMAP.event.addListener(infoWindow, 'content_changed', function () {
        let content = $('.gm-style-iw');
        content.addClass('gm-style-iw-custom').prev().hide();
      });

      return infoWindow;
    }

    function defaultInfoSensorRow(sensor, value, timestamp, color, main) {
      let valueText = !isNaN(parseFloat(value))
        ? $filter('number')(value, sensorService.getSensorAccuracy(sensor)) +
          (sensor.unit ? ' [' + sensor.unit + ']' : '')
        : '-';
      let dateText = timestamp ? $filter('date')(timestamp, 'yyyy-MM-dd HH:mm:ss') : '';
      color = color || '#becdd9';

      let content = `<div class="pull-left ${sensor.id}" style="margin-bottom: 4px; width: 100%">
        <div class="row no-space">
          <div class="nowrap col-xs-5 sensor-name" title="${sensor.name}"><span>${
        sensor.name
      } </span></div>
          <div class="sensor-value col-xs-7">
            <span class="label" style="background: ${color}; color: ${utils.getTextColor(
        color
      )}" title="${dateText}">${valueText}</span>
          </div>
        </div>
      </div>`;

      if (main) {
        content +=
          '<span class="label label-primary label-main" title="Marker Status Variable"><i class="fas fa-map-marker"></i></span>';
      }

      return content;
    }
  }
})();
