(() => {
  const angular = window.angular;

  VirtualExpressionService.$inject = ['Customer', 'AppUtils'];

  angular.module('commonServices').service('VirtualExpressionService', VirtualExpressionService);

  function VirtualExpressionService(Customer, utils) {
    return {
      find: find,
      findById: findById,
      getVariables: getVariables,
      getReadableExpression: getReadableExpression,
      getExpressionToSave: getExpressionToSave,
    };

    function find(customerId, projectId, filter) {
      return Customer.prototype$__get__projects__virtualExpressions({
        id: customerId,
        nk: projectId,
        filter: filter,
      }).$promise.catch((err) => {
        throw utils.getHTTPError(err);
      });
    }

    function findById(customerId, projectId, virtualExpressionId, filter) {
      filter = filter || {};
      filter.where = filter.where || {};
      filter.where.id = virtualExpressionId;

      return find(customerId, projectId, filter)
        .then((result) => {
          if (!result.length) {
            const err = new Error('Not Found');
            err.code = 'NOT_FOUND';
            err.statusCode = 404;
            throw err;
          }
          return result[0];
        })
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function getVariables(expression) {
      if (!expression) {
        return;
      }

      const parsed = math.parse(expression);
      let variables = {};

      parsed.filter((node, path) => {
        if (node.isSymbolNode && path !== 'fn') {
          const name = node.name.startsWith('$') ? node.name.substring(1) : node.name;
          const data = name.split('$');
          const id = data[0];
          if (!variables[node.name]) {
            variables[node.name] = {
              variable: id,
              path: data.slice(1),
            };
          }
        }
      });

      return variables;
    }

    function getReadableExpression(virtualExpression, virtualVariables) {
      if (!virtualExpression.expression) {
        return;
      }
      return transformExpression(
        virtualExpression,
        virtualVariables,
        virtualExpression.expression,
        ['name', 'id'],
        '@'
      );
    }

    function getExpressionToSave(virtualExpression, virtualVariables) {
      if (!virtualExpression.readableExpression) {
        return;
      }
      const expression = virtualExpression.readableExpression.replace(/@/g, '$');
      return transformExpression(
        virtualExpression,
        virtualVariables,
        expression,
        ['id', 'name'],
        '$'
      );
    }

    function transformExpression(
      virtualExpression,
      virtualVariables,
      expression,
      property,
      startChar
    ) {
      try {
        const expressionVariables = getVariables(expression);

        for (let prop in expressionVariables) {
          const current = expressionVariables[prop];
          const variable = virtualVariables.find(
            (variable) => variable[property[1]] === current.variable
          );

          const variableName = variable ? variable[property[0]] : null;
          if (variableName) {
            const regex = new RegExp('\\$' + current.variable + '\\$', 'g');
            expression = expression.replace(regex, startChar + variableName + '$');
          }
        }
        return expression === '$' ? '' : expression;
      } catch (e) {
        // console.log(e);
      }
    }
  }
})();
