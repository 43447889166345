(() => {
  (() => {
    Service.$inject = [];

    angular.module('commonServices').service('ColorUtils', Service);

    function Service() {
      let colors = {
        primary: '#2c303b',
        secondary: '#2172c3',
        info: '#57889c',
        success: '#00a86b',
        warning: '#f6af6d',
        danger: '#ef5350',
      };
      const styles = {};

      return {
        colors: colors,
        getColor,
        shadeColor,
        hexToRGBString,
        hexToRGBAString,
      };

      function getColor(name) {
        if (name === 'main') {
          return styles[window.style] ? styles[window.style] : '#00a489';
        }
        return colors[name];
      }

      /**
       *
       * @param {string} color
       * @param {number} percent
       * @returns {string}
       */
      function shadeColor(color, percent) {
        let R = parseInt(color.substring(1, 3), 16);
        let G = parseInt(color.substring(3, 5), 16);
        let B = parseInt(color.substring(5, 7), 16);

        R = parseInt((R * (100 + percent)) / 100);
        G = parseInt((G * (100 + percent)) / 100);
        B = parseInt((B * (100 + percent)) / 100);

        R = R < 255 ? R : 255;
        G = G < 255 ? G : 255;
        B = B < 255 ? B : 255;

        const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
        const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
        const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

        return '#' + RR + GG + BB;
      }

      function hexToRGB(hex) {
        // Asegurarse de que el valor hexadecimal no tenga el símbolo "#"
        let cleanHex = hex.startsWith('#') ? hex.slice(1) : hex;

        // Manejar colores en formato corto (ejemplo: #123 es RGB 11, 22, 33)
        if (cleanHex.length === 3) {
          cleanHex = cleanHex
            .split('')
            .map((char) => char + char)
            .join('');
        }

        // Comprobar si el valor hexadecimal es válido
        if (cleanHex.length !== 6 || /[^\da-fA-F]/.test(cleanHex)) {
          return null;
        }

        const bigint = parseInt(cleanHex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return { r, g, b };
      }

      function hexToRGBString(hex) {
        const rgb = hexToRGB(hex);
        if (rgb) {
          return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
        }
        return null;
      }

      function hexToRGBA(hex, alpha) {
        const rgb = hexToRGB(hex);
        if (rgb) {
          return {
            r: rgb.r,
            g: rgb.g,
            b: rgb.b,
            a: alpha,
          };
        }
        return null;
      }

      function hexToRGBAString(hex, alpha) {
        const rgba = hexToRGBA(hex, alpha);
        if (rgba) {
          return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
        }
        return null;
      }
    }
  })();
})();
