(function () {
  const angular = window.angular;
  const $ = window.$;

  AppUtils.$inject = ['$http', '$q', '$translate', 'NumberUtils', 'DateUtils'];

  angular.module('commonServices').service('AppUtils', AppUtils);

  function AppUtils($http, $q, $translate, NumberUtils, DateUtils) {
    const textTester = document.getElementById('text-tester');

    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    });

    return {
      isEmptyObject: isEmptyObject,
      isValidNumber: NumberUtils.isValidNumber,
      sortedArray: sortedArray,
      sortArrayByField: sortArrayByField,
      sortComparator: sortComparator,
      objectToArray: objectToArray,
      arrayToObject: arrayToObject,
      generateUUID: generateUUID,
      getObjectValue: getObjectValue,
      uploadFileToUrl: uploadFileToUrl,
      getTextWidth: getTextWidth,
      parallelPromises: parallelPromises,
      inOrderPromises: inOrderPromises,
      chunkParallelPromises,
      getTextColor: getTextColor,
      hexToRgb: hexToRgb,
      getScrollbarWidth: getScrollbarWidth,
      getEntityFileUrl: getEntityFileUrl,
      getContextualizationDownloadUrl,
      validId: validId,
      getHTTPError: getHTTPError,
      blobToFile: blobToFile,
      getSensorStates: getSensorStates,
      capitalize: capitalize,
      decapitalize: decapitalize,
      removeAccents: removeAccents,
      trimEllipsis: trimEllipsis,
      isDate: isDate,
      isPlainObject: isPlainObject,
      getValidDate: getValidDate,
      printDiv: printDiv,
      arrayToChunks: arrayToChunks,
      loadImage,
      semverCompare,
      getStatus,
      evenlyPickItemsFromArray,
      strContains,
      Promise: $q,
    };

    function isEmptyObject(obj) {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          return false;
        }
      }
      return true;
    }

    function sortedArray(prop) {
      let array = [];

      if (!prop) {
        return array;
      }

      array.add = function (element) {
        let added = false;
        for (let i = 0; i < array.length; i++) {
          let curr = array[i];

          if (curr[prop] > element[prop]) {
            array.splice(i, 0, element);
            added = true;
            break;
          }
        }

        if (added === false) {
          array.push(element);
        }
      };
      return array;
    }

    function sortArrayByField(array, field, order) {
      return array.sort((a, b) => sortComparator(a[field], b[field], order));
    }

    function sortComparator(valueA, valueB, order) {
      order = order || 'asc';
      const factor = order === 'desc' ? -1 : 1;
      return factor * collator.compare(valueA, valueB);
    }

    function binarySearch(array, el, compareFn) {
      let m = 0;
      let n = array.length - 1;
      while (m <= n) {
        var k = (n + m) >> 1;
        var cmp = compareFn(el, array[k]);
        if (cmp > 0) {
          m = k + 1;
        } else if (cmp < 0) {
          n = k - 1;
        } else {
          return k;
        }
      }
      return -m - 1;
    }

    function objectToArray(obj) {
      let array = [];

      for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          array.push(obj[prop]);
        }
      }

      return array;
    }

    function arrayToObject(array, key) {
      key = key || 'id';

      if (!array) {
        return {};
      }

      let obj = {};
      for (let el of array) {
        obj[el[key]] = el;
      }

      return obj;
    }

    function generateUUID() {
      function _p8(s) {
        let p = (Math.random().toString(16) + '000000000').substr(2, 8);
        return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
      }

      return _p8() + _p8(true) + _p8(true) + _p8();
    }

    function getTextWidth(text, cssClass, style) {
      if (!text) {
        return 0;
      }

      if (cssClass) {
        textTester.classList.add(...cssClass.split(' '));
      }
      if (style && typeof style === 'object') {
        for (let prop in style) {
          textTester.style[prop] = style[prop];
        }
      }

      textTester.textContent = text;
      let width = Math.ceil(textTester.offsetWidth);

      textTester.className = '';
      textTester.removeAttribute('style');
      return width;
    }

    function uploadFileToUrl(uploadUrl, file, params, callback) {
      let fd = new FormData();

      fd.append('file', file);
      /*for (let prop in params) {
        if (params.hasOwnProperty(prop)) {
          fd.append(prop, params[prop])
        }
      }*/

      let request = $http({
        url: uploadUrl,
        data: fd,
        cache: false,
        method: 'POST',
        transformRequest: angular.identity,
        headers: {
          'Content-Type': undefined,
        },
      });

      if (callback) {
        request
          .then(function (response) {
            callback(null, response.data);
          })
          .catch(function (response) {
            callback(getHTTPError(response));
          });
      } else {
        return request;
      }
    }

    function getScrollbarWidth() {
      let outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.width = '100px';
      outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

      document.body.appendChild(outer);

      let widthNoScroll = outer.offsetWidth;
      outer.style.overflow = 'scroll';

      let inner = document.createElement('div');
      inner.style.width = '100%';
      outer.appendChild(inner);

      let widthWithScroll = inner.offsetWidth;
      outer.parentNode.removeChild(outer);

      return widthNoScroll - widthWithScroll;
    }

    function chunkParallelPromises(promises, size) {
      const chunks = arrayToChunks(promises, size);

      const inOrder = [];
      for (let chunk of chunks) {
        inOrder.push({ function: parallelPromises, args: [chunk] });
      }

      let errors = new Array(promises.length).fill(null);
      let results = new Array(promises.length).fill(null);
      return inOrderPromises(inOrder).then((result) => {
        const oSuccess = result.success;

        for (let i = 0; i < oSuccess.length; i++) {
          const pSuccess = oSuccess[i].success;
          const pError = oSuccess[i].errors;

          for (let j = 0; j < pSuccess.length; j++) {
            const res = pSuccess[j];
            const index = i * size + j;

            if (res) {
              results[index] = res;
            } else {
              errors[index] = pError[j];
            }
          }
        }

        return { success: results, errors: errors };
      });
    }

    /**
     *
     * @param {Array.<{function: function, args: *[]}> } promises
     * @returns {Promise<{errors:[], success:[]}>}
     */
    function parallelPromises(promises) {
      if (!Array.isArray(promises)) {
        return $q.reject('Invalid promises array');
      }

      if (!promises.length) {
        return $q.resolve({ errors: [], success: [] });
      }

      let errors = new Array(promises.length).fill(null);
      let results = new Array(promises.length).fill(null);
      let count = 0;

      const defer = $q.defer();
      promises.forEach(function (current, index) {
        const func = current.function;
        const args = current.args;
        let promise = func.apply(null, args);
        promise = promise.$promise ? promise.$promise : promise;
        promise
          .then(function (result) {
            results[index] = result;

            count++;
            if (count === promises.length) {
              defer.resolve({ success: results, errors: errors });
            }
          })
          .catch(function (err) {
            errors[index] = getHTTPError(err);
            count++;

            if (count === promises.length) {
              defer.resolve({ success: results, errors: errors });
            }
          });
      });

      return defer.promise;
    }

    function inOrderPromises(promises) {
      if (!Array.isArray(promises)) {
        return $q.reject('Invalid promises array');
      }

      if (!promises.length) {
        return $q.resolve({ errors: [], success: [] });
      }

      let errors = new Array(promises.length).fill(null);
      let results = new Array(promises.length).fill(null);
      let count = 0;

      return $q.resolve(
        promises.reduce((previous, current) => {
          return previous.then((result) => {
            const func = current.function;
            const args = current.args;
            let promise = func.apply(null, args);
            promise = promise.$promise ? promise.$promise : promise;
            return promise
              .then((data) => {
                result.success[count] = data;
                count++;
                return result;
              })
              .catch((err) => {
                result.errors[count] = getHTTPError(err);
                count++;
                return result;
              });
          });
        }, $q.resolve({ success: results, errors: errors }))
      );
    }

    function hexToRgb(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    }

    function getObjectValue(o, s) {
      try {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, ''); // strip a leading dot
        let a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
          let k = a[i];
          if (k in o) {
            o = o[k];
          } else {
            return;
          }
        }
        return o;
      } catch (e) {}
    }

    function colourIsLight(r, g, b) {
      const light = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
      return light < 0.5;
    }

    function getTextColor(bgColor) {
      if (!bgColor) {
        return '#111111';
      }

      let rgb = hexToRgb(bgColor);
      let isLight = colourIsLight(rgb.r, rgb.g, rgb.b);

      return isLight ? '#111111' : '#ffffff';
    }

    /**
     *
     * @param model
     * @param entityId
     * @param property
     * @param file
     * @param token
     * @returns {string}
     */
    function getEntityFileUrl(model, entityId, property, file, token) {
      let url = `${window.apiPath}/${model}s/${entityId}/container/download/${file.name}?access_token=${token}&property=${property}`;
      if (file.datasourceName) {
        url += '&datasourceName=' + file.datasourceName;
      }

      if (window.useStorageSignedUrl) {
        url += '&signed-url=true';
      }

      return url;
    }

    function getContextualizationDownloadUrl(customerId, assetId, token) {
      return `${window.apiPath}/customers/${customerId}/assets/${assetId}/export?access_token=${token}`;
    }

    function validId(id) {
      if (id) return id + '';
      return id;
    }

    function getHTTPError(response, options) {
      if (!response) {
        return;
      }
      const error = new Error();

      if (response.data) {
        const err = response.data.error;
        if (err) {
          error.message = err.message;
          error.code = err.code;
          error.codes = err.details?.codes;
        } else {
          error.message = response.data;
        }
      } else {
        error.message = response.message || 'An unknown error has occurred';
        error.code = response.code || 'UNKNOWN_ERROR';
      }
      if (options) {
        error.redirectTo = options.redirectTo;
        error.params = options.params;
      }
      if (error.code === 'LOGIN_FAILED') {
        delete response.resource;
      }

      if (error.message.startsWith('E11000 duplicate key')) {
        error.code = 'ALREADY_EXIST';
        error.message = $translate.instant('errors.ALREADY_EXIST');
      }

      return error;
    }

    function blobToFile(blob, fileName) {
      let croppedFile;
      fileName = fileName || new Date().getTime();
      try {
        croppedFile = new File([blob], fileName, {
          type: blob.type,
          size: blob.size,
        });
      } catch (e) {
        blob.name = fileName;
        blob.lastModified = new Date().getTime();
        croppedFile = blob;
      }

      return croppedFile;
    }

    function getSensorStates(sensor, state) {
      if (!sensor) {
        return null;
      }

      let bands = [
        {
          color: '#cccccc',
          to: 0,
          from: -1,
          thickness: '4%',
        },
        {
          color: '#cccccc',
          to: 1,
          from: 0,
          thickness: '4%',
        },
      ];

      if (!state) {
        return bands;
      }

      let avg = null;
      let len = null;
      let interval = null;
      const accuracy = sensor.parameters ? sensor.parameters.accuracy || 2 : 2;
      let decimals = Math.pow(10, accuracy);

      const colors = {
        G: '#609450',
        Y: '#fbd75b',
        R: '#dc2127',
      };

      const thresholds = [].concat(state.thresholds);
      const thresholdsLen = state.thresholdType === 'NONE' ? 0 : state.thresholdType.length;
      if (thresholdsLen > 0) {
        if (thresholdsLen === 2) {
          const value = thresholds[0].properties.max;
          avg = Math.abs(value) / 2 < 1 ? 1 : Math.abs(value) / 2;
        } else {
          len = thresholdsLen - 2;
          interval = thresholds[thresholdsLen - 2].properties.max - thresholds[1].properties.min;
          avg = Math.round((interval / len) * decimals) / decimals;
        }

        if (!isNaN(avg)) {
          bands = [];
          for (let i = 0; i < thresholdsLen; i++) {
            const threshold = thresholds[i];
            const properties = threshold.properties;
            if (i === 0) {
              properties.min = properties.max - avg;
              properties.min = Math.round(properties.min * decimals) / decimals;
            } else if (i === thresholdsLen - 1) {
              properties.max = properties.min + avg;
              properties.max = Math.round(properties.max * decimals) / decimals;
            }

            const color = threshold.color ? threshold.color : colors[sensor.thresholdType[i]];
            const band = {
              color: color,
              to: properties.max,
              from: properties.min,
              thickness: '4%',
            };

            bands.push(band);
          }
        }
      }

      return bands;
    }

    function capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function decapitalize(str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    }

    function removeAccents(str) {
      const accents = 'ÑÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüŠšŸÿýŽžñ';
      const accentsOut = 'NAAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuSsYyyZzn';
      str = str.split('');
      str.forEach(function (letter, index) {
        const i = accents.indexOf(letter);
        if (letter === 'ñ') {
          //console.log(accentsOut[i]);
        }

        if (i !== -1) {
          str[index] = accentsOut[i];
        }
      });
      return str.join('');
    }

    function trimEllipsis(str, length) {
      return str.length > length ? str.substring(0, length) + '…' : str;
    }

    function isDate(date) {
      return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime());
    }

    function isPlainObject(input) {
      return input && !Array.isArray(input) && typeof input === 'object';
    }

    function getValidDate(date) {
      if (!angular.isDate(date)) {
        date = new Date(date);
      }

      return isNaN(date.getTime()) ? null : new Date(date.getTime());
    }

    function arrayToChunks(array, size) {
      return [].concat.apply(
        [],
        array.map(function (elem, i) {
          return i % size ? [] : [array.slice(i, i + size)];
        })
      );
    }

    function evenlyPickItemsFromArray(items, n) {
      if (items.length <= 2 * n) {
        const shuffled = items.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, n);
      }
      const result = [];
      const totalItems = items.length;
      // default interval between items (might be float)
      const interval = totalItems / n;

      for (let i = 0; i < n; i++) {
        const evenIndex = Math.floor(i * interval + interval / 2);
        result.push(items[evenIndex]);
      }
      return result;
    }

    function printDiv(divId, title) {
      const content = document.getElementById(divId).innerHTML;
      const auxWindow = window.open('', 'Print', 'height=600,width=800');

      auxWindow.document.write(`<html>
        <head>
          <title>${title || 'Print'}</title>
          <link rel="stylesheet" href="${
            window.assetsPath
          }/css/main.css" type="text/css" media="screen, print"/>
        </head>`);
      auxWindow.document.write('<body>');
      auxWindow.document.write(content);
      auxWindow.document.write('</body></html>');
      setTimeout(() => {
        auxWindow.document.close();
        auxWindow.focus();
        auxWindow.print();
        auxWindow.close();
      }, 1000);
      return true;
    }

    function loadImage(src) {
      return $q((resolve, reject) => {
        if (!src) {
          return resolve(null);
        }
        const image = new window.Image();
        image.crossOrigin = 'anonymous';
        image.src = src;
        image.onload = function () {
          resolve(image);
        };
        image.onerror = function (err) {
          reject();
        };
      });
    }

    function semverCompare(a, b) {
      if (!a || !b) {
        if (!a && !b) {
          return 0;
        }
        return !a ? -1 : 1;
      }
      a = a.split('-');
      b = b.split('-');
      const pa = a[0].split('.');
      const pb = b[0].split('.');
      for (let i = 0; i < 3; i++) {
        const na = Number(pa[i]);
        const nb = Number(pb[i]);
        if (na > nb) return 1;
        if (nb > na) return -1;
        if (!isNaN(na) && isNaN(nb)) return 1;
        if (isNaN(na) && !isNaN(nb)) return -1;
      }
      if (a[1] && b[1]) {
        return a[1] > b[1] ? 1 : a[1] < b[1] ? -1 : 0;
      }
      return !a[1] && b[1] ? 1 : a[1] && !b[1] ? -1 : 0;
    }

    function getStatus(date, timeConfig) {
      const now = new Date();
      if (typeof timeConfig === 'string') {
        switch (timeConfig) {
          case 'requested':
            timeConfig = { online: 10, outdated: 60 };
            break;
          case 'background':
            timeConfig = { online: 16, outdated: 60 };
            break;
          case 'data':
            timeConfig = { online: 10, outdated: 60 };
            break;
          default:
            timeConfig = null;
        }
      }

      timeConfig = Object.assign({ online: 10, outdated: 60 }, timeConfig || {});

      const minutes = DateUtils.minutesBetween(date, now);
      if (minutes < timeConfig.online) {
        return 'online';
      } else if (minutes < timeConfig.outdated) {
        return 'outdated';
      }

      return 'offline';
    }

    function strRemoveAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    /**
     * Function that return true if a search string is contained in a string
     */
    function strContains(str, search) {
      return strRemoveAccents(str.toLowerCase()).includes(strRemoveAccents(search.toLowerCase()));
    }
  }
})();
