(() => {
  DateUtils.$inject = [];

  angular.module('commonServices').service('DateUtils', DateUtils);

  function DateUtils() {
    return {
      isDate,
      pad,
      daysBetween,
      minutesBetween,
      allDaysBetween,
      hoursBetween,
      allHoursBetween,
      getMonday,
      getUTCDay,
      timestampFormat,
      initDatePicker,
    };

    function isDate(value) {
      return toString.call(value) === '[object Date]';
    }

    function daysBetween(date1, date2) {
      return Math.abs(Math.floor((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24)));
    }

    function minutesBetween(date1, date2) {
      return Math.abs(Math.floor((date2.getTime() - date1.getTime()) / (1000 * 60)));
    }

    function allDaysBetween(date1, date2) {
      if (date1 >= date2) {
        return [];
      }

      const days = [];
      for (let date = new Date(date1.getTime()); date <= date2; date.setDate(date.getDate() + 1)) {
        const current = new Date(date.getTime());
        current.setHours(0, 0, 0, 0);
        days.push(current);
      }
      return days;
    }

    function hoursBetween(date1, date2) {
      return Math.abs(Math.floor((date2.getTime() - date1.getTime()) / (1000 * 3600)));
    }

    function allHoursBetween(date1, date2) {
      if (date1 >= date2) {
        return [];
      }

      const hours = [];
      for (
        let date = new Date(date1.getTime());
        date <= date2;
        date.setHours(date.getHours() + 1)
      ) {
        const current = new Date(date.getTime());
        current.setMinutes(0, 0, 0);
        hours.push(current);
      }
      return hours;
    }

    function pad(number, n = -2) {
      return ('0' + number).slice(n);
    }

    function getMonday(d) {
      d = new Date(d);
      const day = d.getDay();
      const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
      d.setDate(diff);
      d.setHours(0, 0, 0, 0);
      return new Date(d);
    }

    function getUTCDay(date) {
      const year = date.getFullYear();
      const month = pad(date.getMonth());
      const day = pad(date.getDate());
      return new Date(`${year}-${month}-${day}`);
    }

    function setValueInISODate(dateString, value, valueType) {
      // '2020-08-18T14:32:45.236Z'
      const [date, time] = dateString.split('T');
      let [year, month, day] = date.split(':');
      let [hour, minute, second, miliSeconds] = time.replace('Z').split(/[:.]/);

      const match = '-';
      const matchIndex = 1;
      switch (valueType) {
        case 'year':
          year = value;
          break;
        case 'month':
          month = value;
          break;
        case 'date':
          day = value;
          break;
        case 'hour':
          hour = value;
          break;
        case 'minute':
          minute = value;
          break;
        case 'second':
          second = value;
          break;
      }
    }

    function timestampFormat(timestamp, option) {
      let date = new Date(timestamp);
      let hours = pad(date.getHours());
      let minutes = pad(date.getMinutes());
      let seconds = pad(date.getSeconds());
      let year = date.getFullYear();
      let month = pad(date.getMonth() + 1);
      let day = pad(date.getDate());

      switch (option) {
        case 'time':
          return hours + ':' + minutes + ':' + seconds;
        case 'date':
          return year + '/' + month + '/' + day;
        default:
          return year + '/' + month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;
      }
    }

    // Initialises date picker component
    function initDatePicker(days, endDate) {
      let from, to;
      if (endDate) {
        to = endDate.value ? endDate.value : endDate;
      } else {
        to = new Date();
        to.setMinutes(to.getMinutes() + 1, 0, 0);
      }
      from = new Date(to.getTime());
      from.setDate(from.getDate() - days);
      // from.setHours(from.getHours() - days);
      return {
        initTo: { value: to, options: { showWeeks: false, showMeridian: false } },
        initFrom: { value: from, options: { showWeeks: false, showMeridian: false } },
      };
    }
  }
})();
