; (() => {

  let angular = window.angular;

  LocalStorageService.$inject = [
    'NumberUtils'
  ];

  angular
    .module('commonServices')
    .service('LocalStorageService', LocalStorageService);

  function LocalStorageService(NumberUtils) {
    const TTL = 30 * 60 * 1000; // 30 minutos en milis
    const prefix = "$Xompass$";

    return {
      setItem: setItem,
      getItem: getItem,
      removeItem: removeItem,
      clear: clear
    };

    // Guarda un item simple sin anidacion bajo formato
    // key: { value: str, expiry: int }
    // Se añade opcion para que si se entrega _value null,
    // se considere como objeto vacio para anidacion posterior
    function setItem(key, _value, ttl) {
      let _ttl = null;
      const now = new Date();

      _ttl = NumberUtils.isValidNumber(ttl) ? ttl : TTL;

      let _expiry = now.getTime() + _ttl;

      let _json = {
        value: _value ? _value : {},
        expiry: _expiry
      };

      let finalKey = prefixKey(key);
      try {
        localStorage.setItem(finalKey, JSON.stringify(_json));
      }
      catch (e) {
        console.log(e);
      }
    }

    function getItem(key) {
      let finalKey = prefixKey(key);
      const itemStr = localStorage.getItem(finalKey);
      // Retorna nulo si no existe la key almacenada
      if (!itemStr) {
        return null;
      }
      // Verificar que se tenga almacenado un string
      try {
        const item = JSON.parse(itemStr);
        const now = new Date();
        // Comparar tiempo de expiracion con tiempo actual
        if (now.getTime() > item.expiry) {
          // Si expiro se elimina del storage
          // y se retorna null
          removeItem(finalKey);
          return null;
        }
        return item.value;
      }
      catch (e) {
        console.log(e);
        // console.log(itemStr);
        return null;
      }
    }

    function removeItem(key) {
      let finalKey = prefixKey(key);
      localStorage.removeItem(finalKey);
    }

    // leer localstorage
    // filtrar por prefix
    // Verificar si hay que filtrar por contexto tambien
    // eliminar todo lo que tenga el prefix

    function clear(context) {
      context = prefix + (context || '');
      let _localKeys = Object.keys(localStorage).filter(key => key.startsWith(context));
      _localKeys.forEach((_finalKey) => {
        localStorage.removeItem(_finalKey);
      });
    }

    function prefixKey(key) {
      return prefix + key;
    }
  }
})();