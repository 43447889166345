(() => {
  Service.$inject = ['AppUtils', 'AssetType'];

  angular.module('commonServices').service('AssetTypeService', Service);

  function Service(utils, AssetType) {
    return {
      getCenterTypes,
      isCenterType,
    };

    function getCenterTypes() {
      return ['ObjectRecognitionZone', 'Store'];
    }

    function isCenterType(type) {
      return getCenterTypes().indexOf(type) !== -1;
    }
  }
})();
