(function () {
  'use strict';

  let angular = window.angular;

  ToolService.$inject = ['$q', 'Customer', 'AppUtils', '$translate'];

  angular.module('commonServices').service('ToolService', ToolService);

  function ToolService($q, Customer, utils, $translate) {
    return {
      getTools: getTools,
      getToolsModules: getToolsModules,
    };

    function getTools(customerId) {
      return Customer.prototype$__get__tools({
        id: customerId,
        filter: { include: 'assetTypes' },
      }).$promise.catch((err) => {
        throw utils.getHTTPError(err);
      });
    }

    function getToolsModules() {
      return {
        xompassRealTime: {
          module: 'xompassRealTime',
          path: 'monitoring.realTime({assetId: null})',
          icon: 'fas fa-tachometer-alt',
        },
        xompassTimeSeries: {
          module: 'xompassTimeSeries',
          path: 'monitoring.timeSeries({assetId: null})',
          icon: 'svg-icon svg-icon-line-chart',
        },
        xompassKeyValueData: {
          module: 'xompassKeyValueData',
          path: 'monitoring.keyValue({assetId: null})',
          icon: 'fas fa-table',
        },
        xompassDetectionHistory: {
          module: 'xompassDetectionHistory',
          path: 'detectionsHistory({assetId: null})',
          icon: 'fas fa-image',
        },
        xompassForensicAnalysis: {
          module: 'xompassForensicAnalysis',
          path: 'forensicAnalysis',
          icon: 'fas fa-images',
        },
        xompassGIS: {
          module: 'GIS',
          path: { base: 'gis', view: 'home' },
          icon: 'fas fa-map-marker',
          permission: 'gis',
        },
        xompassOpticalFiberGis: {
          module: 'xompassOpticalFiberGis',
          path: { base: 'fiber', view: 'home' },
          icon: 'fas fa-tree',
        },
        xompassWildfireGis: {
          module: 'xompassWildfireGIS',
          path: { base: 'wildfire', view: 'home' },
          icon: 'fas fa-tree',
        },
        xompassSCADA: {
          module: 'xompassSCADA',
          path: { base: 'scada', view: 'home' },
          icon: 'fas fa-sitemap',
          permission: 'processLayouts',
        },
        xompassEmbeddedReports: {
          module: 'xompassEmbeddedReport',
          path: { base: 'reports', view: 'embedded({reportId: null})' },
          icon: 'svg-icon svg-icon-pbi',
          permission: 'embeddedReports',
        },
        xompassTrafficFlowAnalysis: {
          module: 'xompassTrafficFlowAnalysis',
          path: { base: 'tfa', view: 'home' },
          icon: 'fas fa-chart-line',
          permission: 'trafficFlowAnalysis',
        },
        xompassBIPanels: {
          module: 'xompassBIPanels',
          path: { base: 'biPanel', view: 'home' },
          icon: 'fas fa-chart-area',
          permission: 'biPanels',
        },
        xompassPeopleCounterReports: {
          module: 'xompassPeopleCounterReports',
          path: { base: 'pcr', view: 'home' },
          icon: 'fas fa-chart-bar',
          permission: 'peopleCounterReports',
        },
        xompassEdgeMonitoring: {
          module: 'xompassEdgeMonitoring',
          path: 'edge-monitoring',
          icon: 'fas fa-wifi fa-fw',
        },
        xompassEventHistory: {
          module: 'xompassEventHistory',
          path: 'events.history',
          icon: 'svg-icon svg-icon-event-summary',
        },
        xompassEventDashboard: {
          module: 'xompassEventDashboard',
          path: 'events.dashboard.home',
          icon: 'svg-icon svg-icon-event-dashboard',
          permission: 'eventDashboards',
        },
        xompassStoreAnomaliesDashboard: {
          module: 'xompassStoreAnomaliesDashboard',
          path: 'events.store-anomalies.home',
          icon: 'svg-icon svg-icon-event-dashboard',
          permission: 'eventDashboards',
        },
        xompassEventReport: {
          module: 'xompassEventReport',
          path: 'events.report',
          icon: 'fas fa-chart-bar',
        },
        xompassStoreAnomaliesReport: {
          module: 'xompassStoreAnomaliesReport',
          path: 'events.store-anomalies.report',
          icon: 'fas fa-chart-bar',
        },
        xompassCCTV: {
          module: 'xompassCCTV',
          path: 'cctv',
          icon: 'fas fa-video',
        },
        xompassDetectionsHeatmap: {
          module: 'xompassDetectionsHeatmap',
          path: 'detectionsHeatmap({assetId: null})',
          icon: 'fas fa-grip-horizontal',
        },
        xompassStoreHeatmap: {
          module: 'xompassStoreHeatmap',
          path: 'storeHeatmap({assetId: null})',
          icon: 'fas fa-store',
        },
        xompassRoundsReport: {
          module: 'xompassRoundsReport',
          path: 'roundsreport',
          icon: 'svg-icon svg-guard-round',
        },
        xompassStoreOpeningReport: {
          module: 'xompassStoreOpeningReport',
          path: 'storeOpeningReport',
          icon: 'svg-icon svg-store-opened',
        },
        xompassForensicRetail: {
          module: 'xompassForensicRetail',
          path: 'forensicRetail',
          icon: 'fas fa-photo-video',
        },
        xompassStoreForensicSales: {
          module: 'xompassStoreForensicSales',
          path: 'storeForensicSales',
          icon: 'fas fa-cash-register',
        },
        xompassForensicLicensePlate: {
          module: 'xompassForensicLicensePlate',
          path: 'forensicLicensePlates',
          icon: 'svg-icon svg-license-plate',
        },
        xompassForensicReconstruction: {
          module: 'xompassForensicReconstruction',
          path: 'forensicReconstruction',
          icon: 'svg-icon svg-forensic',
        },
        xompassStoryline: {
          module: 'xompassStoryline',
          path: 'storyline',
          icon: 'fas fa-film',
          permission: 'storyline',
        },
        xompassLCEventReport: {
          module: 'xompassLCEventReport',
          path: 'annualReport',
          icon: 'fas fa-book-reader',
        },
        vsaasGranularity: {
          module: 'vsaasGranularity',
          path: 'granularity-dashboard',
          icon: 'svg-icon svg-rocks',
        },
        vsaasCargoContainerLog: {
          module: 'vsaasCargoContainerLog',
          path: 'cargoContainerLog',
          icon: 'svg-icon svg-container',
        },
        vsaasTelegramEvents: {
          module: 'vsaasTelegramEvents',
          path: 'telegramEvents({groupId: null})',
          icon: 'fab fa-telegram',
        },
        vsaasCountReport: {
          module: 'vsaasCountReport',
          path: 'countReport({countReportId: null})',
          icon: 'fas fa-chart-bar',
        },
        vsaasOperabilitySummary: {
          module: 'vsaasOperabilitySummary',
          path: 'operabilitySummary',
          icon: 'fas fa-file-invoice',
        },
      };
    }
  }
})();
