(function () {
  'use strict';
  const angular = window.angular;

  ObjectRecognitionUtilsService.$inject = [];

  angular
    .module('commonServices')
    .service('ObjectRecognitionUtilsService', ObjectRecognitionUtilsService);

  function ObjectRecognitionUtilsService() {
    return {
      isInsideZone: isInsideZone,
      getCentroid,
      getDetectionCentroid,
    };

    function isInsideZone(point, polygon) {
      // ray-casting algorithm based on
      // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

      const x = point.x;
      const y = point.y;

      let inside = false;
      for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].x;
        const yi = polygon[i].y;
        const xj = polygon[j].x;
        const yj = polygon[j].y;

        const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) {
          inside = !inside;
        }
      }

      return inside;
    }

    function getCentroid(polygon) {
      var minX, maxX, minY, maxY;
      for (var i = 0; i < polygon.length; i++) {
        minX = polygon[i].x < minX || minX == null ? polygon[i].x : minX;
        maxX = polygon[i].x > maxX || maxX == null ? polygon[i].x : maxX;
        minY = polygon[i].y < minY || minY == null ? polygon[i].y : minY;
        maxY = polygon[i].y > maxY || maxY == null ? polygon[i].y : maxY;
      }
      return {
        x: (minX + maxX) / 2,
        y: (minY + maxY) / 2,
      };
    }

    function getDetectionCentroid(detection, sensorType) {
      if (detection.class !== 'person' && sensorType !== 'MultiZoneObjectTracking') {
        return { x: detection.frame.x, y: detection.frame.y };
      }

      const height = detection.frame.h / 2;
      return { x: detection.frame.x, y: detection.frame.y + height };
    }
  }
})();
