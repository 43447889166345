;(function () {
  'use strict';

  /**
   * Loopback Filter.
   * @typedef {object} LoopbackFilter
   * @property {object} where
   * @property {object} fields
   * @property {object} include
   */

  angular
    .module('commonServices', [
      'angular-storage'
    ]);
})();
