(function () {
  'use strict';

  let angular = window.angular;

  UserService.$inject = ['$q', 'AppUtils', 'Admin', 'Customer', 'LoopBackAuth'];

  angular.module('commonServices').service('ProjectService', UserService);

  function UserService($q, utils, LoopBackUser, Customer) {
    return {
      findProjects: findProjects,
      findById: findById,
      createProject: createProject,
      saveProject: saveProject,
      deleteProject: deleteProject,
      getModel: getModel,
    };

    function findProjects(customerId, filter) {
      let query = { id: customerId };
      if (filter) {
        query.filter = filter;
      }

      if (!query.filter || !query.filter.order) {
        query.filter = query.filter || {};
        query.filter.order = 'name ASC';
      }

      return Customer.projects(query).$promise.catch((err) => {
        throw utils.getHTTPError(err);
      });
    }

    function findById(customerId, projectId, filter) {
      filter = filter || {};
      filter.where = { id: projectId };

      let defer = $q.defer();
      Customer.projects({ id: customerId, filter: filter })
        .$promise.then((result) => {
          if (!result || !result.length) {
            const err = new Error('Not Found');
            err.code = 'NOT_FOUND';
            err.statusCode = 404;
            return defer.reject(err);
          }

          defer.resolve(result[0]);
        })
        .catch((err) => {
          throw utils.getHTTPError(err);
        });

      return defer.promise;
    }

    function createProject(customerId, project) {
      return Customer.prototype$__instantiate__toolkits(
        {
          id: customerId,
          fk: project.toolkitId,
        },
        {
          name: project.name,
          description: project.description,
        }
      ).$promise.catch((err) => {
        throw utils.getHTTPError(err);
      });
    }

    function saveProject(customerId, project) {
      return Customer.projects
        .updateById(
          {
            id: customerId,
            fk: project.id,
          },
          {
            name: project.name,
            description: project.description,
          }
        )
        .$promise.catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function deleteProject(customerId, projectId) {
      return Customer.projects
        .destroyById({ id: customerId, fk: projectId })
        .$promise.catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function getModel() {
      return LoopBackUser.modelName;
    }
  }
})();
