;(function () {
  'use strict';

  let angular = window.angular;

  KeywordService.$inject = ['LoopBackAuth', 'AppUtils', '$http'];

  angular
    .module('commonServices')
    .service('KeywordService', KeywordService);

  function KeywordService (LoopBackAuth, utils, $http) {
    return {
      find: getKeywords
    };

    function getKeywords (filter) {
      let url = window.apiPath + '/Keywords?access_token=' + LoopBackAuth.accessTokenId;

      if (filter) {
        url += '&filter=' + encodeURI(JSON.stringify(filter));
      }

      return $http
        .get(url)
        .then(response => {
          return response.data;
        })
        .catch(err => {
          throw utils.getHTTPError(err);
        });
    }

  }
})();
