(() => {
  const angular = window.angular;
  const maxParallelRequests = 4;

  Service.$inject = ['$rootScope', 'Customer', 'AppUtils'];

  angular.module('commonServices').service('EdgeDeviceService', Service);

  function Service($root, Customer, utils) {
    return {
      getVersions,
      getUptimes,
      loadEdgeAgents,
    };

    function getVersions(devicesIds) {
      const customerId = $root.customerId;
      const chunks = utils.arrayToChunks(devicesIds, 50);

      const requests = [];
      chunks.forEach((current) => {
        requests.push({
          function: Customer.prototype$findDeviceVersions,
          args: [
            {
              id: customerId,
              where: { id: { inq: current } },
            },
          ],
        });
      });

      return makeRequests(requests);
    }

    function getUptimes(devicesIds) {
      const customerId = $root.customerId;
      const chunks = utils.arrayToChunks(devicesIds, 50);

      const requests = [];
      chunks.forEach((current) => {
        requests.push({
          function: Customer.prototype$findDeviceUptimes,
          args: [
            {
              id: customerId,
              where: { id: { inq: current } },
            },
          ],
        });
      });

      return makeRequests(requests);
    }

    function makeRequests(requests) {
      const chunks = utils.arrayToChunks(requests, maxParallelRequests);

      const promises = [];
      for (let chunk of chunks) {
        promises.push({ function: utils.parallelPromises, args: [chunk] });
      }

      return utils
        .inOrderPromises(promises)
        .then((results) => {
          const versions = { successful: [], errors: [] };
          results.success.forEach((result) => {
            result.success.forEach((current) => {
              if (current) {
                Array.prototype.push.apply(versions.successful, current.successful);
                Array.prototype.push.apply(versions.errors, current.errors);
              }
            });
          });
          return versions;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function loadEdgeAgents(customerId, deviceId) {
      return Customer.prototype$__get__devices__edgeAgents({
        id: customerId,
        nk: deviceId,
        filter: {
          include: [
            {
              relation: 'assets',
              scope: {
                fields: ['id', 'name', 'assetId', 'projectId', '_config'],
                include: {
                  relation: 'asset',
                  scope: { fields: ['id', 'name', '_config'] },
                },
              },
            },
            {
              relation: 'edgeAgentAssets',
              scope: {
                fields: ['created', 'assetId'],
                order: 'created ASC',
              },
            },
          ],
        },
      }).$promise.then((edgeAgents) => {
        for (let edgeAgent of edgeAgents) {
          const assets = [];
          edgeAgent.edgeAgentAssets = edgeAgent.edgeAgentAssets || [];
          edgeAgent.assets = edgeAgent.assets || [];

          edgeAgent.templateId = edgeAgent.templateTagId
            ? edgeAgent.templateTagId.split('/')[0]
            : null;

          edgeAgent.edgeAgentAssets.filter((edgeAgentAsset) => {
            const asset = edgeAgent.assets.find((current) => current.id === edgeAgentAsset.assetId);
            if (!asset) {
              return;
            }
            assets.push(asset);
            return true;
          });
          edgeAgent.assets = assets;
        }
        return edgeAgents;
      });
    }
  }
})();
