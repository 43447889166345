;(() => {
  angular
    .module('commonServices')
    .factory('EventUIStore', ['store', function (store) {
      return store.getNamespacedStore('event_ui');
    }])
    .factory('DeviceEventUIStore', ['store', function (store) {
      return store.getNamespacedStore('device_event_ui');
    }])
    .factory('UptimeUIStore', ['store', function (store) {
      return store.getNamespacedStore('uptime_ui');
    }]);
})();
