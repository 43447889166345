(function () {
  'use strict';
  const angular = window.angular;

  ObjectRecognitionService.$inject = ['$translate', '$rootScope'];

  angular.module('commonServices').service('ObjectRecognitionService', ObjectRecognitionService);

  function ObjectRecognitionService($translate, $root) {
    return {
      getClasses: getClasses,
      getPoses: getPoses,
      getPosePoints: getPosePoints,
      getPoseLines: getPoseLines,
      getBoxPoints: getBoxPoints,
      getPoseFrame: getPoseFrame,
      getValidObjects: getValidObjects,
      getValidPoses: getValidPoses,
      getYOLOClasses,
      getColors,
    };

    function getClasses() {
      return $root.project?.yoloClasses?.length ? $root.project.yoloClasses : getYOLOClasses();
      // return [
      //   { value: 'person', label: $translate.instant('entities.sensor.__classes.person') },
      //   { value: 'car', label: $translate.instant('entities.sensor.__classes.car') },
      //   { value: 'bicycle', label: $translate.instant('entities.sensor.__classes.bicycle') },
      //   { value: 'motorbike', label: $translate.instant('entities.sensor.__classes.motorbike') },
      //   { value: 'bus', label: $translate.instant('entities.sensor.__classes.bus') },
      //   { value: 'license_plate', label: $translate.instant('entities.sensor.__classes.license_plate'), disabled: true },
      //   { value: 'bag', label: $translate.instant('entities.sensor.__classes.bag'), disabled: true },
      //   { value: 'firehydrant', label: $translate.instant('entities.sensor.__classes.firehydrant'), disabled: true },
      //   { value: 'cone', label: $translate.instant('entities.sensor.__classes.cone'), disabled: true },
      //   { value: 'barrier', label: $translate.instant('entities.sensor.__classes.barrier'), disabled: true },
      //   { value: 'trashbin', label: $translate.instant('entities.sensor.__classes.trashbin'), disabled: true },
      //   { value: 'truck', label: $translate.instant('entities.sensor.__classes.truck'), disabled: true },
      //   { value: 'forklift', label: $translate.instant('entities.sensor.__classes.forklift'), disabled: true },
      //   { value: 'excavator', label: $translate.instant('entities.sensor.__classes.excavator'), disabled: true },
      //   { value: 'bulldozer', label: $translate.instant('entities.sensor.__classes.bulldozer'), disabled: true },
      //   { value: 'flatbed-trailer', label: $translate.instant('entities.sensor.__classes.flatbed-trailer'), disabled: true },
      //   { value: 'pet', label: $translate.instant('entities.sensor.__classes.pet'), disabled: true },
      //   { value: 'machinery', label: $translate.instant('entities.sensor.__classes.machinery'), disabled: true },
      // ];
    }

    function getPoses(ignoreBlackListed) {
      const blackListed = ['none', 'standing'];
      const poses = [
        { value: 'none', upper: 'NONE', label: $translate.instant('entities.sensor.__poses.none') },
        {
          value: 'hands_up',
          upper: 'HANDS_UP',
          label: $translate.instant('entities.sensor.__poses.hands_up'),
        },
        {
          value: 'on_floor',
          upper: 'ON_FLOOR',
          label: $translate.instant('entities.sensor.__poses.on_floor'),
        },
        {
          value: 'running',
          upper: 'RUNNING',
          label: $translate.instant('entities.sensor.__poses.running'),
        },
        {
          value: 'seated',
          upper: 'SEATED',
          label: $translate.instant('entities.sensor.__poses.seated'),
        },
        {
          value: 'standing',
          upper: 'STANDING',
          label: $translate.instant('entities.sensor.__poses.standing'),
        },
        {
          value: 'throwing_things',
          upper: 'THROWING_THINGS',
          label: $translate.instant('entities.sensor.__poses.throwing_things'),
        },
        {
          value: 'fight',
          upper: 'FIGHT',
          label: $translate.instant('entities.sensor.__poses.fight'),
        },
        {
          value: 'squat_heur1',
          upper: 'SQUAT_HEUR1',
          label: $translate.instant('entities.sensor.__poses.squat_heur1'),
        },
        {
          value: 'squat_heur2',
          upper: 'SQUAT_HEUR2',
          label: $translate.instant('entities.sensor.__poses.squat_heur2'),
        },
      ];

      return ignoreBlackListed
        ? poses.filter((current) => blackListed.indexOf(current.value) === -1)
        : poses;
    }

    function getPosePoints() {
      return [
        {
          value: 'nose',
          label: $translate.instant('entities.sensor.__poses.points.nose'),
          color: '#00ffff',
        },
        {
          value: 'l_eye',
          label: $translate.instant('entities.sensor.__poses.points.l_eye'),
          color: '#00bfff',
        },
        {
          value: 'r_eye',
          label: $translate.instant('entities.sensor.__poses.points.r_eye'),
          color: '#00ff66',
        },
        {
          value: 'l_ear',
          label: $translate.instant('entities.sensor.__poses.points.l_ear'),
          color: '#004dff',
        },
        {
          value: 'r_ear',
          label: $translate.instant('entities.sensor.__poses.points.r_ear'),
          color: '#00ff00',
        },
        {
          value: 'l_shoulder',
          label: $translate.instant('entities.sensor.__poses.points.l_shoulder'),
          color: '#4dffde',
        },
        {
          value: 'r_shoulder',
          label: $translate.instant('entities.sensor.__poses.points.r_shoulder'),
          color: '#4dffde',
        },
        {
          value: 'l_elbow',
          label: $translate.instant('entities.sensor.__poses.points.l_elbow'),
          color: '#007fff',
        },
        {
          value: 'r_elbow',
          label: $translate.instant('entities.sensor.__poses.points.r_elbow'),
          color: '#ff9c7f',
        },
        {
          value: 'l_wrist',
          label: $translate.instant('entities.sensor.__poses.points.l_wrist'),
          color: '#007fff',
        },
        {
          value: 'r_wrist',
          label: $translate.instant('entities.sensor.__poses.points.r_wrist'),
          color: '#ff9c7f',
        },
        {
          value: 'l_hip',
          label: $translate.instant('entities.sensor.__poses.points.l_hip'),
          color: '#4dffde',
        },
        {
          value: 'r_hip',
          label: $translate.instant('entities.sensor.__poses.points.r_hip'),
          color: '#4dffde',
        },
        {
          value: 'l_knee',
          label: $translate.instant('entities.sensor.__poses.points.l_knee'),
          color: '#004dff',
        },
        {
          value: 'r_knee',
          label: $translate.instant('entities.sensor.__poses.points.r_knee'),
          color: '#ff4d24',
        },
        {
          value: 'l_ankle',
          label: $translate.instant('entities.sensor.__poses.points.l_ankle'),
          color: '#004dff',
        },
        {
          value: 'r_ankle',
          label: $translate.instant('entities.sensor.__poses.points.r_ankle'),
          color: '#ff4d24',
        },
        {
          value: 'neck',
          label: $translate.instant('entities.sensor.__poses.points.neck'),
          color: '#4dffde',
        },
      ];
    }

    function getPoseLines() {
      return [
        // shoulders
        { pair: ['l_shoulder', 'neck', 'r_shoulder'], color: '#4dffde' },
        // left arm
        { pair: ['l_shoulder', 'l_elbow'], color: '#007fff' },
        { pair: ['l_elbow', 'l_wrist'], color: '#007fff' },
        //right arm
        { pair: ['r_shoulder', 'r_elbow'], color: '#ff9c7f' },
        { pair: ['r_elbow', 'r_wrist'], color: '#ff9c7f' },
        // hip
        { pair: ['r_hip', 'l_hip'], color: '#4dffde' },
        //left trunk
        { pair: ['l_shoulder', 'l_hip'], color: '#4dffde' },
        //right trunk
        { pair: ['r_shoulder', 'r_hip'], color: '#4dffde' },
        //left leg
        { pair: ['l_hip', 'l_knee'], color: '#004dff' },
        { pair: ['l_knee', 'l_ankle'], color: '#004dff' },
        // right leg
        { pair: ['r_hip', 'r_knee'], color: '#ff4d24' },
        { pair: ['r_knee', 'r_ankle'], color: '#ff4d24' },
      ];
    }

    function getBoxPoints(frame, mode) {
      mode = (mode || '').split('-');
      const xMode = mode[0] || 'center';
      const yMode = mode[1] || 'middle';

      const points = [
        { x: null, y: null },
        { x: null, y: null },
        { x: null, y: null },
        { x: null, y: null },
      ];

      switch (xMode) {
        case 'left':
          points[0].x = points[3].x = frame.x;
          points[1].x = points[2].x = frame.x + frame.w;
          break;
        case 'center':
        default:
          points[0].x = points[3].x = frame.x - frame.w / 2;
          points[1].x = points[2].x = frame.x + frame.w / 2;
          break;
      }

      switch (yMode) {
        case 'top':
          points[0].y = points[1].y = frame.y;
          points[2].y = points[3].y = frame.y + frame.h;
          break;
        case 'middle':
        default:
          points[0].y = points[1].y = frame.y - frame.h / 2;
          points[2].y = points[3].y = frame.y + frame.h / 2;
          break;
      }

      return points;
    }

    function getPoseFrame(pose) {
      if (pose.frame) {
        return pose.frame;
      }

      const min = { x: null, y: null };
      const max = { x: null, y: null };
      for (let key in pose.keypoints) {
        if (!pose.keypoints[key].probability) {
          continue;
        }
        const value = pose.keypoints[key];

        let x = parseFloat(value.point.x);
        let y = parseFloat(value.point.y);
        min.x = min.x === null || min.x > x ? x : min.x;
        min.y = min.y === null || min.y > y ? y : min.y;

        max.x = max.x === null || max.x < x ? x : max.x;
        max.y = max.y === null || max.y < y ? y : max.y;
      }

      return {
        x: (max.x + min.x) / 2,
        y: (max.y + min.y) / 2,
        w: max.x - min.x,
        h: max.y - min.y,
      };
    }

    function getValidObjects(objects) {
      const classes = getClasses();
      return objects.filter((current) => {
        return classes.findIndex((_class) => _class.value === current.class) !== -1;
      });
    }

    function getValidPoses(poses) {
      const availablePoses = getPoses(true);
      return poses.filter((current) => {
        return availablePoses.findIndex((pose) => pose.upper === current.label) !== -1;
      });
    }

    function getYOLOClasses() {
      return $root.yoloClasses;
    }

    function getColors() {
      return [
        { label: 'white', value: '#ffffff' },
        { label: 'gray', value: '#808080' },
        { label: 'black', value: '#000000' },
        { label: 'red', value: '#ff0000' },
        { label: 'yellow', value: '#ffff00' },
        { label: 'brown', value: '#a52a2a' },
        { label: 'green', value: '#008000' },
        { label: 'cyan', value: '#00ffff' },
        { label: 'blue', value: '#0000ff' },
        { label: 'purple', value: '#800080' },
      ];
    }
  }
})();
