(function () {
  const $ = window.$;
  const GMAP = window.google ? window.google.maps : null;
  drawManagerService.$inject = ['AppUtils', 'GisUtils', '$timeout'];

  angular.module('commonServices').service('GisDrawService', drawManagerService);

  function drawManagerService(utils, gisUtils, $timeout) {
    this.drawProps = {
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: GMAP ? GMAP.ControlPosition.TOP_CENTER : null,
        drawingModes: GMAP
          ? [
              GMAP.drawing.OverlayType.MARKER,
              GMAP.drawing.OverlayType.POLYGON,
              GMAP.drawing.OverlayType.POLYLINE,
            ]
          : [],
      },
      markerOptions: gisUtils.defaults.point,
      polygonOptions: gisUtils.defaults.lines,
      polylineOptions: gisUtils.defaults.lines,
    };

    this.createDrawManager = function (drawProps, map, events) {
      events = events || {};

      let styles = drawProps.styles || {};
      delete drawProps.styles;

      let drawingManager = new GMAP.drawing.DrawingManager($.extend({}, this.drawProps, drawProps));
      drawingManager.setMap(map.map);

      GMAP.event.addListener(drawingManager, 'overlaycomplete', function (e) {
        // manager.setDrawingMode(null);
        let figure = e.overlay;
        let type = e.type;

        figure.id = utils.generateUUID();

        if (type === GMAP.drawing.OverlayType.MARKER) {
          let pos = figure.getPosition();
          let props = $.extend({}, gisUtils.defaults.point);

          props.lat = pos.lat();
          props.lng = pos.lng();

          if (styles.marker) {
            props.icon = styles.marker;
          }

          figure.setMap(null);
          figure = gisUtils.addMarker(props, map);

          figure.geoType = 'point';
          figure.style = $.extend({}, gisUtils.defaults.point, styles.marker);

          figure.lat = pos.lat();
          figure.lng = pos.lng();

          if (typeof events.onMarker === 'function') {
            $timeout(() => {
              events.onMarker(figure);
            });
          }
        } else if (type === GMAP.drawing.OverlayType.POLYGON) {
          figure.geoType = 'polygon';
          figure.style = $.extend({}, gisUtils.defaults.lines, styles.lines);
          if (typeof events.onPolygon === 'function') {
            $timeout(() => {
              events.onPolygon(figure);
            });
          }
        } else if (type === GMAP.drawing.OverlayType.POLYLINE) {
          figure.geoType = 'lineString';
          figure.style = $.extend({}, gisUtils.defaults.lines, styles.lines);
          if (typeof events.onLineString === 'function') {
            $timeout(() => {
              events.onLineString(figure);
            });
          }
        } else {
          return;
        }

        if (typeof events.onOverlayComplete === 'function') {
          $timeout(() => {
            events.onOverlayComplete(figure);
          });
        }
      });

      GMAP.event.addListener(drawingManager, 'drawingmode_changed', function (e) {
        if (typeof events.modeChanged === 'function') {
          $timeout(() => {
            events.modeChanged(drawingManager.getDrawingMode());
          });
        }
      });

      GMAP.event.addListener(map.map, 'rightclick', function () {
        drawingManager.setDrawingMode(null);
      });

      return drawingManager;
    };
  }
})();
