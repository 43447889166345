; (function () {
  'use strict';

  let angular = window.angular;

  EventService.$inject = ['$translate', 'AppUtils', 'Event', 'EventTrigger', 'LoopBackAuth'];

  angular
    .module('commonServices')
    .service('EventService', EventService);
  function EventService($translate, utils, Event, EventTrigger, LoopBackAuth) {
    return {
      findEvent: findEvent,
      prepareAndSortEvents: prepareAndSortEvents
    };

    function findEvent(event) {

    }

    function prepareAndSortEvents(events, options) {
      if (!events || events.length === 0) {
        return events;
      }

      options = options || {};

      events.forEach(event => {
        event.created = new Date(event.created);
        event._data = event._data || [];
        event._stateChanges = event._stateChanges || [];
        event._comments = event._comments || [];

        event._data.forEach(current => {
          current.from = new Date(current.from);
          current.created = new Date(current.created);
          current.id = parseInt(current.id);
          current.referenceId = current.referenceId ? parseInt(current.referenceId) : null;

          if (typeof options.data === 'function') {
            options.data(event, current);
          }
        });

        event._stateChanges.forEach(current => {
          current.created = new Date(current.created);
          current.modified = new Date(current.modified);
        });

        event._comments.forEach(current => {
          current.created = new Date(current.created);
          current.modified = new Date(current.modified);
        });

        if (event._lastData) {
          event._lastData.from = new Date(event._lastData.from);
          event._lastData.created = new Date(event._lastData.created);
          event._lastData.modified = new Date(event._lastData.modified);
        }
      });

      events.sort((a, b) => a.created > b.created ? 1 : -1);
    }
  }
})();
