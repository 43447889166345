(function () {
  'use strict';
  const angular = window.angular;

  UIModalService.$inject = ['$uibModal', '$translate'];

  angular.module('commonServices').service('uiModalService', UIModalService);

  function UIModalService($uibModal, $translate) {
    return {
      confirm: confirm,
    };

    function confirm(options) {
      options = options || {};
      return $uibModal.open({
        windowClass: options.windowClass || 'modal-xs',
        template: `<div class="modal-header">{{ctrl.title}}</div>
        <div class="modal-body text-center" ng-bind-html="ctrl.message"></div>
        <div class="modal-footer">
            <button class="btn btn-default btn-sm pull-left" ng-click="ctrl.modalInstance.close(false)" translate>buttons.cancel</button>
            <button class="btn btn-primary btn-sm" ng-click="ctrl.modalInstance.close(true)" translate>buttons.accept</button>
        </div>`,
        backdrop: 'static',
        keyboard: false,
        animation: false,
        bindToController: true,
        controllerAs: 'ctrl',
        size: options.size || 'sm',
        controller: [
          '$uibModalInstance',
          function ($uibModalInstance) {
            const vm = this;

            vm.modalInstance = $uibModalInstance;
            vm.title = options.title || $translate.instant('buttons.confirm');
            vm.message = options.message || $translate.instant('general._messages.operationCheck');
          },
        ],
      }).result;
    }

    function alert(options) {
      options = options || {};
      return $uibModal.open({
        size: 'sm',
        windowClass: 'modal-xs',
        template: `<div class="modal-header">{{ctrl.title}}</div>
        <div class="modal-body text-center padding10" ng-bind-html="ctrl.message"></div>
        <div class="modal-footer">
            <button class="btn btn-default btn-xs pull-left" ng-click="ctrl.modalInstance.close(false)" translate>buttons.close</button>
        </div>`,
        backdrop: 'static',
        keyboard: false,
        bindToController: true,
        controllerAs: 'ctrl',
        controller: [
          '$uibModalInstance',
          function ($uibModalInstance) {
            const vm = this;

            vm.modalInstance = $uibModalInstance;
            vm.title = options.title || 'buttons.';
          },
        ],
      }).result;
    }
  }
})();
