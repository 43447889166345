(() => {
  angular
    .module('commonServices')
    .service('DetectionCanvasUtilsService', DetectionCanvasUtilsService);

  DetectionCanvasUtilsService.$inject = [
    'ObjectRecognitionUtilsService',
    'ColorUtils',
    'SensorService',
    'ObjectRecognitionService',
    'AppUtils',
    '$translate',
  ];

  const segmentationColors = [
    '#7f6000',
    '#3d85c6',
    '#cc0000',
    '#274e13',
    '#1c4587',
    '#d9d2e9',
    '#8e7cc3',
    '#20124d',
    '#9900ff',
    '#00ff00',
    '#0c343d',
    '#ff9900',
    '#3c78d8',
    '#666666',
    '#660000',
    '#ffd966',
    '#45818e',
    '#674ea7',
    '#d0e0e3',
    '#f4cccc',
    '#6d9eeb',
    '#9fc5e8',
    '#073763',
    '#c9daf8',
    '#76a5af',
    '#ff0000',
    '#6aa84f',
    '#93c47d',
    '#783f04',
    '#434343',
    '#f6b26b',
    '#e06666',
    '#b6d7a8',
    '#4a86e8',
    '#a4c2f4',
    '#ea9999',
    '#d9ead3',
    '#e69138',
    '#a2c4c9',
    '#cfe2f3',
    '#6fa8dc',
    '#f9cb9c',
    '#fff2cc',
    '#fce5cd',
    '#00ffff',
    '#ffff00',
    '#b4a7d6',
    '#f1c232',
    '#cccccc',
    '#d9d9d9',
  ];

  const linesColors = {
    person: { bg: '#b245f1', text: '#000000' },
    car: { bg: '#49b9da', text: '#000000' },
    default: { bg: '#fcfa1e', text: '#000000' },
    danger: { bg: '#ff0000', text: '#ffffff' }, // red
    success: { bg: '#008000', text: '#ffffff' }, // green
  };

  function DetectionCanvasUtilsService(
    ObjectRecognitionUtilsService,
    ColorUtils,
    SensorService,
    ObjectRecognitionService,
    utils,
    $translate
  ) {
    let _classes = [];
    let _poses = [];
    let _posePoints = [];
    let _bodyPairs = [];
    let _allowedClasses = [];
    let _classesMap = {};
    let _posesMap = {};
    let _posePointsMap = {};

    // baseCanvasWidth is used to calculate the with of the stroke and the radius of the centroid
    let baseCanvasWidth = 1000;

    const service = {
      drawDetections,
      drawSensorZone,
      drawZone,
    };

    return service;

    /**
     * Draw detections on a canvas
     * @param {Object} options
     * @param {HTMLImageElement} options.image - Image to draw detections on
     * @param {Object} options.data - A sensor data object
     * @param {Object} options.sensor - Sensor to get the zone from
     * @param {Boolean} options.drawZone - Draw the zone on the canvas
     * @param {Boolean} options.drawLabels - Draw the labels on the canvas
     * @param {Boolean} options.drawOverImage - Draw the canvas over the image. If false, a transparent canvas will be created
     *
     * @returns {HTMLCanvasElement} - Canvas with the detections drawn on it
     */
    function drawDetections({
      canvas,
      image,
      data,
      sensor,
      drawZone,
      drawLabels,
      drawOverImage,
      filterByZone,
      copy,
    }) {
      _classes = SensorService.getDetectorClasses();
      _poses = ObjectRecognitionService.getPoses();
      _posePoints = ObjectRecognitionService.getPosePoints();
      _bodyPairs = ObjectRecognitionService.getPoseLines();
      _allowedClasses = _classes.map((current) => current.value);
      _classesMap = utils.arrayToObject(_classes, 'value');
      _posesMap = utils.arrayToObject(_poses, 'value');
      _posePointsMap = utils.arrayToObject(_posePoints, 'value');

      drawZone = drawZone !== false;
      drawLabels = drawLabels !== false;
      drawOverImage = drawOverImage === true;

      if (copy !== false) {
        data = structuredClone(data);
      }

      if (canvas) {
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
      }

      canvas = canvas || document.createElement('canvas');
      canvas.crossOrigin = 'anonymous';

      canvas.width = image.width;
      canvas.height = image.height;

      const ratio = image.width / image.naturalWidth;
      const strokeWidth = Math.max(Math.round((2 * canvas.width) / baseCanvasWidth), 1);

      const ctx = canvas.getContext('2d');
      if (drawOverImage) {
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.width,
          image.height
        );
      }

      if (drawZone) {
        drawSensorZone({ ctx, sensor, ratio, strokeWidth });
      }

      drawObjects(ctx, data.content, sensor, ratio, {
        drawLabels,
        strokeWidth,
        filterByZone: filterByZone,
      });

      drawPoses(ctx, data.content, sensor, ratio, {
        drawLabels,
        strokeWidth,
        filterByZone,
      });

      return canvas;
    }

    function drawObjects(ctx, dataContent, sensor, ratio, options) {
      if (!dataContent || typeof dataContent !== 'object') {
        return;
      }

      const objects = dataContent.objects || dataContent.detections || dataContent.faces;

      if (!objects?.length) {
        return;
      }

      const strokeWidth = options?.strokeWidth || 1;
      const type = sensor.type;
      const pairs = dataContent.pairs || [];
      const drawLabels = !!options?.drawLabels;
      const filterByZone = options?.filterByZone;
      const cutLabels = options?.cutLabels;
      const boxMode =
        type === 'FaceDetection' || type === 'FaceMaskDetection' ? 'left-top' : 'center-middle';

      const frames = [];
      let currentSegmentationColor = 0;

      const filteredObjects = objects.filter((object) => {
        if (!object.frame || (object.class && !_allowedClasses.includes(object.class))) {
          return false;
        }

        if (type === 'StoppedLicensePlate' && !object.stopped) {
          return false;
        }

        if (filterByZone && !isFiltered(object, sensor)) {
          object.hidden = true;
          return false;
        }

        return true;
      });

      const needsHighlight = filteredObjects.some((object) => {
        if (sensor.type === 'LineCrossingDetection' && object.direction) {
          return true;
        }

        return (
          object.noPlateDetected ||
          object.triggerSubject ||
          object.missingContainee ||
          object.filteredBy?.BLACKLIST
        );
      });

      for (let i = 0; i < filteredObjects.length; i++) {
        const object = filteredObjects[i];
        if (!object.frame || (object.class && !_allowedClasses.includes(object.class))) {
          continue;
        }

        if (type === 'StoppedLicensePlate' && !object.stopped) {
          continue;
        }

        if (filterByZone && !isFiltered(object, sensor)) {
          object.hidden = true;
          continue;
        }

        const frame = structuredClone(object.frame);
        frame._index = i;
        frames.push(frame);

        const label = drawLabels ? getDetectionLabel(object, cutLabels) : null;

        let bgColor;
        let textColor;
        let points;
        if (object.points) {
          const color = segmentationColors[++currentSegmentationColor % segmentationColors.length];
          drawZone(
            ctx,
            {
              points: object.points.map((c) => {
                return { x: c[0], y: c[1] };
              }),
              color,
            },
            { fill: true, strokeWidth, ratio }
          );

          points = ObjectRecognitionService.getBoxPoints(frame, boxMode);
          bgColor = color;
          textColor = utils.getTextColor(color);
        } else {
          const bbox = ObjectRecognitionService.getBoxPoints(frame, boxMode);

          let color;
          if (pairs.length) {
            const hasPair = pairs.some((pair) => pair.includes(i));
            color = hasPair ? linesColors.danger : linesColors.success;
          } else {
            let highlight =
              object.noPlateDetected ||
              object.triggerSubject ||
              object.filteredBy?.BLACKLIST ||
              object.missingContainee;
            if (sensor.type === 'LineCrossingDetection' && object.direction) {
              highlight = true;
            }

            if (needsHighlight) {
              color = highlight ? linesColors.danger : linesColors.success;
            } else {
              color = highlight
                ? linesColors.danger
                : linesColors[object.class] || linesColors.default;
            }
          }

          points = bbox;
          bgColor = color.bg;
          textColor = color.text;
          drawZone(ctx, { points, color: bgColor }, { fill: false, strokeWidth, ratio });
        }

        if (!object.landmarks && !object.keypoints) {
          drawCentroid(ctx, object, bgColor, strokeWidth, ratio, sensor.type);
        }

        if (filteredObjects.length <= 50) {
          drawLabel(
            ctx,
            label,
            points,
            strokeWidth,
            {
              bg: bgColor,
              text: textColor,
            },
            ratio
          );
        }

        if (object.keypoints) {
          drawSkeleton(ctx, object.keypoints, ratio, strokeWidth);
        }

        if (object.landmarks) {
          drawLandmarks(ctx, object.landmarks, ratio, strokeWidth);
        }

        if (object.trace) {
          drawTrace(ctx, object, ratio, strokeWidth);
        }
      }
    }

    function drawPoses(ctx, dataContent, sensor, ratio, options) {
      if (!dataContent || typeof dataContent !== 'object') {
        return;
      }

      const poses = dataContent.poses;

      if (!poses?.length) {
        return;
      }

      const detections = poses
        .filter((c) => c.label !== 'NONE')
        .map((pose) => {
          return {
            displayName: _posesMap[pose.label.toLowerCase()].label,
            frame: ObjectRecognitionService.getPoseFrame(pose),
            label: pose.label,
            keypoints: pose.keypoints,
          };
        });

      drawObjects(ctx, { objects: detections }, sensor, ratio, options);
    }

    function drawSensorZone({ ctx, sensor, defaultStrokeWidth, ratio, opacity = 0.2 }) {
      const strokeWidth = Math.floor(defaultStrokeWidth / 2);
      const parameters = sensor.parameters;
      if (!parameters?.zones && !parameters?.points) {
        return;
      }

      if (parameters?.zones) {
        parameters.zones.forEach((zone) => {
          drawZone(ctx, zone, { fill: true, strokeWidth, ratio, opacity: opacity });
        });
      }

      if (parameters?.points) {
        drawZone(ctx, parameters, { fill: true, strokeWidth, ratio, opacity: opacity });
      }
    }

    function drawZone(ctx, zone, options) {
      const fill = !!options?.fill;
      const opacity = options?.opacity || 0.2;
      const strokeWidth = options?.strokeWidth || 1;
      const ratio = options?.ratio || 1;

      const color = zone.color || '#ffffff';
      const points = zone.points;

      ctx.beginPath();
      ctx.lineWidth = strokeWidth;

      ctx.strokeStyle = color;

      ctx.moveTo(applyRatio(points[0].x, ratio), applyRatio(points[0].y, ratio));

      for (let i = 1; i < points.length; i++) {
        ctx.lineTo(applyRatio(points[i].x, ratio), applyRatio(points[i].y, ratio));
      }

      if (points.length > 2) {
        ctx.closePath();
      }

      ctx.stroke();

      if (fill && points.length > 2) {
        ctx.fillStyle = ColorUtils.hexToRGBAString(color, opacity);
        ctx.fill();
      }

      // draw the arrow to indicate the direction of the line
      if (points.length === 2) {
        const arrowWidth = 13 / ratio;

        let p1 = points[0];
        let p2 = points[1];

        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        const len = Math.sqrt(dx * dx + dy * dy);
        dx = dx / len;
        dy = dy / len;

        const mid = {
          x: (p2.x + p1.x) / 2,
          y: (p2.y + p1.y) / 2,
        };
        const start = {
          x: mid.x - arrowWidth * dy,
          y: mid.y + arrowWidth * dx,
        };

        const angle = Math.atan2(dy, dx);

        p1 = {
          x: start.x + Math.cos(angle),
          y: start.y + Math.sin(angle),
        };

        p2 = {
          x: start.x - 2 * Math.sin(Math.PI - angle),
          y: start.y - 2 * Math.cos(Math.PI - angle),
        };

        const p3 = {
          x: start.x - Math.cos(angle),
          y: start.y - Math.sin(angle),
        };
        ctx.beginPath();
        ctx.moveTo(applyRatio(start.x, ratio), applyRatio(start.y, ratio));
        ctx.lineTo(applyRatio(p1.x, ratio), applyRatio(p1.y, ratio));
        ctx.lineTo(applyRatio(p2.x, ratio), applyRatio(p2.y, ratio));
        ctx.lineTo(applyRatio(p3.x, ratio), applyRatio(p3.y, ratio));

        ctx.lineWidth = strokeWidth * 2;

        ctx.strokeStyle = color;
        ctx.closePath();
        ctx.stroke();
      }
    }

    function drawCentroid(ctx, detection, color, defaultStrokeWidth, ratio, sensorType) {
      const radius = Math.max(Math.floor(defaultStrokeWidth * 1.5), 2);
      const centroid = ObjectRecognitionUtilsService.getDetectionCentroid(detection, sensorType);
      ctx.beginPath();
      ctx.arc(
        applyRatio(centroid.x, ratio),
        applyRatio(centroid.y, ratio),
        radius,
        0,
        2 * Math.PI,
        false
      );
      ctx.fillStyle = color;
      ctx.fill();
    }

    function drawLabel(ctx, label, points, strokeWidth, color, ratio) {
      if (!label) {
        return;
      }

      const fontSize = 9;
      const padding = 2;
      ctx.save();
      ctx.font = `${fontSize}px sans-serif`;
      ctx.textBaseline = 'top';
      ctx.fillStyle = color.bg;

      const p1 = points[0];
      const p3 = points[2];
      const textWidth = ctx.measureText(label).width;

      const pos = { x: p1.x, y: p1.y };
      if (pos.y < 0) {
        pos.y = p3.y;
      }

      pos.x = applyRatio(pos.x, ratio) - strokeWidth / 2;
      pos.y = applyRatio(pos.y, ratio) - fontSize - padding;

      /// draw background rect assuming height of font
      ctx.fillRect(pos.x, pos.y, textWidth + padding * 2, fontSize + padding);

      ctx.fillStyle = color.text;
      /// draw text on top
      ctx.fillText(label, pos.x + padding, pos.y + padding / 2);

      /// restore original state
      ctx.restore();
    }

    function drawSkeleton(ctx, keypoints, ratio, strokeWidth) {
      const radius = Math.max(Math.floor(strokeWidth * 1.5), 2);
      const bannedPoints = ['l_eye', 'r_eye', 'l_ear', 'r_ear', 'nose'];
      const minPrabability = 0.1;
      for (let key in keypoints) {
        const value = keypoints[key];
        key = key.toLowerCase();

        const probability = parseFloat(value.probability);

        if (probability < minPrabability || bannedPoints.includes(key)) {
          continue;
        }

        const x = applyRatio(parseFloat(value.point.x), ratio);
        const y = applyRatio(parseFloat(value.point.y), ratio);

        const posePoint = _posePointsMap[key] || { color: '#ffff00' };

        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = posePoint.color;
        ctx.fill();
      }

      const keypointsKeys = Object.keys(keypoints);
      for (const bodyPair of _bodyPairs) {
        let valid = 0;

        const pair = bodyPair.pair.filter((c) => {
          const keyPointKey = keypointsKeys.find((keyPoint) => keyPoint.toLowerCase() === c);
          const keyPoint = keypoints[keyPointKey];
          return keyPoint && keyPoint.probability > minPrabability;
        });

        if (pair.length < 2) {
          continue;
        }

        for (let i = 0; i < pair.length; i++) {
          const keyPointKey = keypointsKeys.find((keyPoint) => keyPoint.toLowerCase() === pair[i]);
          const keyPoint = keypoints[keyPointKey];

          const x = applyRatio(keyPoint.point.x, ratio);
          const y = applyRatio(keyPoint.point.y, ratio);

          if (i === 0) {
            ctx.beginPath();
            ctx.moveTo(x, y);
            continue;
          }

          ctx.lineTo(x, y);

          if (i === pair.length - 1) {
            ctx.strokeStyle = bodyPair.color;
            ctx.lineWidth = strokeWidth;
            ctx.stroke();
          }
        }
      }
    }

    function drawLandmarks(ctx, landmarks, ratio, strokeWidth) {
      const radius = Math.max(Math.floor(strokeWidth * 1.5), 2);
      let marks;

      if (utils.isPlainObject(landmarks)) {
        marks = Object.values(landmarks);
      } else {
        marks = landmarks;
      }
      marks = Array.isArray(marks) ? marks : [];

      for (const mark of marks) {
        const x = applyRatio(mark.x, ratio);
        const y = applyRatio(mark.y, ratio);
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = '#ffff00';
        ctx.fill();
      }
    }

    function drawTrace(ctx, object, ratio, strokeWidth) {
      const trace = object.trace
        .filter((c) => {
          return Array.isArray(c.frame) || (c.x !== undefined && c.y !== undefined);
        })
        .map((c) => {
          if (c.frame) {
            return { x: c.frame.x, y: c.frame.y };
          }

          return c;
        });

      if (trace.length < 2) {
        return;
      }

      ctx.beginPath();
      ctx.lineWidth = strokeWidth;
      ctx.strokeStyle = '#ff0000';
      ctx.moveTo(applyRatio(trace[0].x, ratio), applyRatio(trace[0].y, ratio));
      for (let i = 1; i < trace.length; i++) {
        ctx.lineTo(applyRatio(trace[i].x, ratio), applyRatio(trace[i].y, ratio));
      }
      ctx.stroke();
    }

    /**
     * Check if an object must be drawn on the canvas
     * @param {Object} object
     * @param {Object} sensor
     * @returns {Boolean} - True if the object must be drawn
     */
    function isFiltered(object, sensor) {
      let filtered = true;
      if (object.filteredBy) {
        if (object.filteredBy.BLACKLIST) {
          return true;
        }

        filtered =
          filtered &&
          Object.values(object.filteredBy).reduce((filtered, value) => filtered && value, true);

        if (object.filteredBy.ZONE !== undefined) {
          return filtered;
        }
      }

      if (!filtered) {
        return false;
      }

      if (!sensor.parameters?.points && !sensor.parameters?.zones) {
        return filtered;
      }

      const centroid = ObjectRecognitionUtilsService.getDetectionCentroid(object, sensor.type);

      if (sensor.parameters?.zones) {
        return sensor.parameters.zones.some((zone) =>
          ObjectRecognitionUtilsService.isInsideZone(centroid, zone.points)
        );
      }
      return ObjectRecognitionUtilsService.isInsideZone(centroid, sensor.parameters.points);
    }

    function getDetectionLabel(detection, cutLabels = false) {
      if (!detection) {
        return null;
      }

      if (detection.displayName) {
        return detection.displayName;
      }

      if (detection.noPlateDetected) {
        return $translate.instant('entities.sensor.noPlateDetected');
      }

      if (detection.missingContainee) {
        const missing = detection.missingContainee;
        let classLabel = _classesMap[missing] ? _classesMap[missing].label : missing;
        if (classLabel && cutLabels) {
          classLabel = classLabel.substring(0, 3);
        }

        const label = classLabel ? classLabel.toLowerCase() : '';
        return $translate.instant('entities.sensor.missingContainee', {
          label,
        });
      }

      if (detection.mask !== undefined) {
        let maskLabel = 'entities.sensor.__faces.';
        maskLabel +=
          detection.mask === null || !detection.mask.probability ? 'notHasMask' : 'hasMask';

        return $translate.instant(maskLabel);
      }

      const _class = detection.class;
      const probability = detection.probability;
      const text = detection.text || detection.licensePlate?.text;
      let classLabel = _classesMap[_class] ? _classesMap[_class].label : _class;
      if (classLabel && cutLabels) {
        classLabel = classLabel.substring(0, 3);
      }

      let label = classLabel ? classLabel.toLowerCase() : '';

      if (text) {
        label = `${label ? label + ' - ' : ''}${text}`;
      } else if (utils.isValidNumber(probability)) {
        label = `${label ? label + ' ' : ''}${probability.toFixed(cutLabels ? 1 : 2)}`;
      }

      return label;
    }

    function applyRatio(val, ratio) {
      return Math.round(10 * val * ratio) / 10;
    }
  }
})();
