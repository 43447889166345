;(function () {
  const angular = window.angular;

  TimingService.$inject = ['$timeout', '$interval'];

  angular
    .module('commonServices')
    .service('TimingService', TimingService);

  function TimingService ($timeout, $interval) {

    function getMillis (end) {
      return end - new Date();
    }

    class DateTimeOut {
      constructor (fn, date) {
        if (!date instanceof Date) {
          throw new Error('Invalid end date');
        }

        if (typeof fn !== 'function') {
          throw new Error('Invalid function');
        }

        this.endDate = date;
        this.fn = fn;
      }

      start () {
        if (this.timer) {
          return;
        }

        this.millis = getMillis(this.endDate);
        if (isNaN(this.millis) || this.millis <= 0) {
          this.finished = true;
          this.timer = null;
          return;
        }

        this.finished = false;
        this.timer = $timeout(() => {
          this.fn();
          this.finished = true;
        }, this.millis);
      }

      stop () {
        if (this.timer) {
          $timeout.cancel(this.timer);
          this.timer = null;
          this.finished = true;
        }
      }

      updateEnd (end) {
        this.endDate = end;
        this.stop();
        this.start();
      }
    }

    return {
      DateTimeOut: DateTimeOut
    };

  }
})();
